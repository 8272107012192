import React, {memo, useCallback, useContext, useState} from 'react';
import {SearchBoxPayView, SearchItem, SearchKey, SearchValue} from '../../styles/sc/admin';
import TextField from './TextField';
import Button from './Button';
import {ModalContext} from "../modal/modalContext";
import AccordionList from "../web/accordions/AccordionList";
import styled from "@emotion/styled";
import {CustomDayDatePicker} from "./DayDatePicker";
import {useQuery} from "@tanstack/react-query";
import {paymentViewList} from "../../api/admin/paymentView";
import {answerEdit} from "../../api/admin/question";
import {Toast} from "../toast";


const TextUnderLine = styled.span`
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  color: #0081ff;
  text-decoration: underline !important;
  cursor: pointer;
`;

const PayViewList = ({
                             className = '',
                             state,
                             searchItems,
                             onClickSearch,
                             onChange,
                         modalTitle,
                         modalContents,
                         update
                         }) => {
    const handleOnChanges = ({ name, value }) => {
        let numericValue = value.replace(/\D/g, '');

        if (name === 'cardNumber') {
            numericValue = numericValue.slice(0, 16);
        }

        if (name === 'authNumber') {
            numericValue = numericValue.slice(0, 8);
        }
        onChange({ name, value: numericValue });
    };
    const datePicker = (item) => (

        <SearchItem key={item.name}>
            <SearchKey>{item.label}</SearchKey>
            <SearchValue>
                <CustomDayDatePicker
                    paymentDate={state.paymentDate || new Date()}
                    setPaymentDate={(value) => onChange({ value: value, name: 'paymentDate' })}
                />
            </SearchValue>
        </SearchItem>
    );

    const genericInput = (item) => (
        <SearchItem key={item.name}>
            <SearchKey>{item.label}</SearchKey>
            <SearchValue>
                <TextField
                    height="42px"
                    width="485px"
                    placeholder={item.placeholder || '검색어를 입력해주세요.'}
                    name={item.name}
                    value={state[item.name] || ''}
                    onChange={({ target: { value } }) =>
                        handleOnChanges({ name: item.name, value })
                    }
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            onClickSearch();
                        }
                    }}
                />
            </SearchValue>
        </SearchItem>
    );

    const { openModal, closeModal } = useContext(ModalContext);

    const handleAgreedModalOpen = useCallback(()=> {
        openModal(modalTitle, {
            confirm: () => closeModal()
        },
            { type: 'agree' },modalContents,
        )
    })



    return (
            <div className={''}>
                <SearchBoxPayView className={''}>
                    <div className={'grid grid-cols-1'}>
                        {searchItems?.map((item) => {
                            switch (item.type) {
                                case 'date':
                                    return datePicker(item);
                                case 'text':
                                    return genericInput(item);
                                case 'payment':
                                    return genericInput(item);
                                case 'card':
                                    return genericInput(item);
                                default:
                                    return null;
                            }
                        })}
                    </div>
                </SearchBoxPayView>
                <div
                    className={'flex flex-row items-center justify-end mt-0 mr-0 mb-6 ml-auto gap-[10px]'}
                >
                    <div className={'text-bodyXXSmall'}>
                        <TextUnderLine onClick={handleAgreedModalOpen}> 개인정보 수집 및 이용약관</TextUnderLine> 동의
                    </div>
                    <Button
                        width={'120px'}
                        height={'46px'}
                        color={'admin_main'}
                        style={{
                            color: 'white',
                        }}
                        onClick={update}
                    >
                        <span>동의 후 조회</span>
                    </Button>
                </div>
            </div>


    );
};

export default memo(PayViewList);
