import React, { createContext } from 'react';
import useModal from './useModal';
import Modal from './Modal';
import SuccessModal from './SuccessModal';
import WaitModal from "./waitingModal";
import AgreedModal from "./AgreedModal";

let ModalContext;
let { Provider } = (ModalContext = createContext());

const ModalProvider = ({ children }) => {
  let { modal, openModal, modalContent, todo, closeModal, modalType,modalSubContent } =
    useModal();
  let confirm = todo?.confirm;
  return (
    <Provider
      value={{
        modal,
        openModal,
        modalContent,
        modalSubContent,
        confirm,
        closeModal,
        modalType,
      }}>
        {modal && (
            modalType === 'success' ? <SuccessModal /> :
                modalType === 'wait' ? <WaitModal /> :
                    modalType === 'agree' ? <AgreedModal /> : <Modal />
        )}
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
