import Banner from '../../../assets/images/mainBanner.png';
import MobileBanner from '../../../assets/images/web/mainBanners/mobileMainBanner.png';
import MobileBannerS from '../../../assets/images/web/mainBanners/mobileMainBanner2.png';
import Banner2 from '../../../assets/images/web/mainBanners/mainImage2.png';
import Section from '../../../layout/web/Section';
import MainColorBtn from '../button/MainColorBtn';
import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import ThirdNoticePopUp from "../../../pages/web/main/ThridNoticePopUp";

const MainBanner = () => {
  const nav = useNavigate();
  const mainBannerRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === 1 ? 0 : prev + 1));
  };
  const callback = (entries, io) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('active');
        io.unobserve(entry.target);
      }
    });
  };
  const sections = new IntersectionObserver(callback, {
    threshold: [0.4],
  });
  useEffect(() => {
    if (mainBannerRef.current) sections.observe(mainBannerRef.current);
    return () => {
      if (mainBannerRef.current) sections.unobserve(mainBannerRef.current);
    };
  }, []);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prev) => (prev === 1 ? 0 : prev + 1));
    }, 5000);

    return () => clearInterval(slideInterval);
  }, []);

  return (
    <MainBannerContainer ref={mainBannerRef} className={`max-w-screen-max`}>
        <ThirdNoticePopUp />
        {/*<NoticePopUp />*/}
      <div className={'absolute h-full w-full '}>
        <div className="relative h-full w-full bg-[#0000002e] transition-[2s] imgSlider">
          <img
            src={Banner}
            alt=""
            className={`absolute w-full h-full top-0 object-cover xs:hidden fold:hidden z-[-2] ${
              currentSlide === 1 ? '-translate-x-full back' : 'translate-x-0'
            }`}
          />
          <img
            src={Banner2}
            alt=""
            className={`absolute w-full h-full top-0 left-full object-cover xs:hidden fold:hidden bg-[red] z-[-2] ${
              currentSlide === 1 ? '-translate-x-full back' : 'translate-x-0'
            }`}
          />
          <img
            src={MobileBanner}
            alt=""
            className={`hidden -z-[2] xs:block fold:block absolute w-full h-full top-0 ${
              currentSlide === 1 ? '-translate-x-full back' : 'translate-x-0'
            } object-cover `}
          />
          <img
            src={MobileBannerS}
            alt=""
            className={`hidden -z-[2] xs:block fold:block absolute w-full h-full top-0 left-full ${
              currentSlide === 1 ? '-translate-x-full back' : 'translate-x-0'
            } object-cover `}
          />
        </div>
        <div className="absolute w-full h-[10px] bottom-[50px] gap-[15px] xs:flex flex flex-row items-center justify-center transition-all">
          <div
            className={`${
              currentSlide === 0 ? 'bg-main' : 'bg-[#FFFFFF80]'
            } w-[10px] h-[10px] rounded-full cursor-pointer`}
            onClick={() => setCurrentSlide(0)}
          />
          <div
            className={`${
              currentSlide === 1 ? 'bg-main' : 'bg-[#FFFFFF80]'
            } w-[10px] h-[10px] rounded-full cursor-pointer`}
            onClick={() => setCurrentSlide(1)}
          />
        </div>
        {/* <img
          src={MobileBanner}
          alt=""
          className={'hidden absolute w-full h-full top-0 left-0 z-[-1] object-cover xs:block fold:block'}
        />
        */}
      </div>
      <Section
        maxSize
        className={
          'h-full flex flex-col items-start justify-center sm:items-center xs:items-center fold:items-center '
        }>
        <span
          className={
            'text-heading2 md:text-heading3 sm:text-heading4 xs:text-bodySmall fold:text-bodySmall xs:leading-[30px] font-[400] mb-[10px] sm:mb-[7px] xs:mb-[5px] fold:text-center fade-in-up'
          }>
          언제 어디서나 안전하고 <br className={'hidden fold:block'} /> 신뢰할 수 있는
        </span>
        <span
          className={
            'text-[42px] font-bold leading-[50px] md:text-heading1 sm:text-heading1 xs:text-heading2 fold:text-heading2 mb-[20px] xs:text-center fold:text-center fade-in-up-sub'
          }>
          온라인 결제 서비스를 <br className={'hidden xs:block fold:block'} />
          시작합니다
        </span>
        <span className={'fade-in-up-btn'}>
          <MainColorBtn
            onClick={() => nav('/apply/service')}
            text={'서비스 신청하기'}
            className={
              'text-bodyNormal sm:text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall px-[30px] py-[13px] rounded-[10px] xs:px-[25px] xs:py-[12px] fold:px-[25px] fold:py-[12px] font-[400]'
            }
          />
        </span>
      </Section>
    </MainBannerContainer>
  );
};

export default MainBanner;

const MainBannerContainer = styled.div`
  max-height: 1000px;
  min-height: 800px;
  height: 80vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.45);
  color: #fff;
  & * {
    transition: all 0.3s;
  }
  .fade-in-up {
    opacity: 0;
    transform: translateY(100px);
    transition:
      opacity 1s,
      transform 0.7s;
  }
  .fade-in-up-sub {
    opacity: 0;
    transform: translateY(100px);
    transition:
      opacity 1s,
      transform 0.7s;
    transition-delay: 0.1s;
  }
  .fade-in-up-btn {
    opacity: 0;
    transform: translateY(100px);
    transition:
      opacity 1s,
      transform 0.7s;
    transition-delay: 0.2s;
  }
  &.active .fade-in-up,
  &.active .fade-in-up-sub,
  &.active .fade-in-up-btn {
    opacity: 1;
    transform: translateY(0);
  }
  & .imgSlider * {
    transition: all 1s;
    & .back {
      transition: all 1s;
    }
  }
`;
