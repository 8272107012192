import Flexbox from "../receipt/Flexbox";
import {useState} from 'react';
import styled from 'styled-components';
import {TextFieldInput} from "../admin/TextField";
import Button from "../admin/Button";
import Text from "../receipt/Text";
import {paymentReceiptMail} from "../../api/admin/paymentView";
import {Toast} from "../toast";

const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  justify-content: center;

  z-index: 99;
`;
const ContentBox = styled.div`
  position: relative;
  width: 406px;
  height: 268px;
  background: #ffffff;
  box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  z-index: 100;
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  /* justify-content: space-between; */
`;

const ReceiptShareModal = ({isOpen, setIsOpen, onClick, btnContent, imageData}) => {
    const [data, setData] = useState({
        email: '',
    });
    const onChangeData = e => {
        const {name, value} = e.currentTarget;
        setData(preVal => ({...preVal, [name]: value}));
    };

    const onCilckBtn = async () => {
        const params = {
            info: imageData,
            mailAddrs: data.email,
        };
        try {
            const res = await paymentReceiptMail(params);
            if (res.data.code == 200) {
                Toast.success('전표메일 발송되었습니다.');
            } else if (res.data.code == 500) {
                Toast.error('전표메일 발송에 실패하였습니다.');
            } else {
                Toast.error('전표메일 발송에 실패하였습니다.');
            }
        } catch (error) {
            Toast.error('전표메일발송에 실패하였습니다.');
        }

        //
        // api.post(API_URL.V1_PAYMENT_MAIL_REceipt, params).then(res => {
        //     if (res.code == '200') {
        //         successToast('메일이 전송되었습니다.');
        //         setIsOpen(false);
        //     } else if (res.code == '500') {
        //         errorToast('전표 메일 발송에 실패하였습니다.');
        //     } else {
        //         successToast(res.message);
        //     }
        // });
    };
    return (
        <>
            <Container>
                <ContentBox>
                    <Text color={'#2C3A4B'} size={24} weight={500} margin={'0px 0 0 0'}>
                        이메일을 전송하시겠습니까?
                    </Text>
                    <Flexbox direction={'column'} margin={'16px 0 0 0'}>
                        <TextFieldInput value={data.email} name="email" onChange={onChangeData} placeholder={'이메일을 입력해주세요.'} width={'346px'} />
                    </Flexbox>
                    <Flexbox gap={16} margin={'32px 0 0 0'}>
                        <Button width={'185px'} height={'43px'} type={'outlined'} color={'#fff'} onClick={() => setIsOpen(false)} border={'1px solid #A5ABB3'}>
                            취소
                        </Button>
                        <Button width={'185px'} height={'43px'} type={'outlined'} fontColor={'#fff'} color={'#0064ff'} onClick={onCilckBtn}>
                            전송
                        </Button>
                    </Flexbox>
                </ContentBox>
            </Container>
        </>
    );
};

export default ReceiptShareModal;
