import receiptCancelImage from 'assets/images/receipt-cancel-image.png'

export const ReceiptCancelImage2 = ({width, height, onClick, isCursor, children}) => {
    const opacity = '0.6';
    return (
        <img
            src={receiptCancelImage}
            alt=""
            width={width}
            onClick={onClick}
            height={height}
            style={{opacity: opacity, zIndex: 3, width: '315px', height: '95px', position: 'absolute', top: 400, left: 20, transform: 'rotate(-25deg)'}}
        />
    );
};
