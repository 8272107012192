import noticePopImg from 'assets/images/notice_0401.jpg';
import {useState} from "react";
const ThirdNoticePopUp = () => {
    const [isPopUp, setIsPopUp] = useState(true);

    // const onClickGoToGoolgeForm = () => {
    //     window.open('https://forms.gle/AhmeAiNysHMxcg1R7', '_blank');
    // }

    const onClickImageClose = () => {
        setIsPopUp(prev => !prev)
    }

    return (
        isPopUp && (
            <div
                style={{zIndex:11}}
                className={`absolute
                            right-[300px] h-[720px] w-[470px] 
                            xs:w-full xs:h-full xs:right-0 xs:top-[100px]
                            ss:w-full xs:h-full ss:right-0 ss:top-[100px]
                            object-cover`}>
                <img src={noticePopImg}
                     alt="공지사항 팝업 이미지"
                     className="w-full h-auto"
                />

                <div className={`w-[30px] h-[30px] relative top-[-650px] right-[-430px] cursor-pointer
                    ss:top-[-500px] ss:right-[-327px]
                    xs:top-[-545px] xs:right-[-358px]
                `}
                     onClick={onClickImageClose}
                >
                </div>

                {/*<div className={`absolute w-[155px] h-[45px] border border-gray-400 top-[540px] left-[158px] cursor-pointer*/}
                {/*    ss:top-[405px] ss:left-[115px]*/}
                {/*    xs:top-[445px] xs:left-[130px]*/}
                {/*    `*/}
                {/*}*/}
                {/*     onClick={onClickGoToGoolgeForm}*/}
                {/*>*/}
                {/*</div>*/}
            </div>
        )
    )
}

export default ThirdNoticePopUp;
