import styled from 'styled-components';

const Box = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justifyContent || 'center'};
  align-items: ${props => props.alignItem || 'center'};
  gap: ${props => `${props.gap}px` || '0px'};
  margin: ${props => props.margin || '0 0 0 0'};
  padding: ${props => props.padding || '0 0 0 0'};
  width: ${props => props.width || '100%'};
  height: ${props => props.height || ''};
  background: ${props => props.background || ''};
  border-radius: ${props => props.borderRadius || 0};
  position: ${props => props.position || 'relative'};
  flex:${props => props.flex || null};
  ${props => props.border && {border: props.border}}
  ${props => props.borderBottom && {borderBottom: props.borderBottom}}
  ${props => props.borderRight && {borderRight: props.borderRight}}
  ${props => props.borderTop && {borderTop: props.borderTop}}
  ${props => props.borderLeft && {borderLeft: props.borderLeft}}
  ${props => props.iswrap && {flexWrap: 'wrap'}}
  ${props => props.isCursor && {cursor: 'pointer'}}
  ${props => props.overflow && {overflow: props.overflow}}
  ${props => props.overflowX && {'overflow-x': props.overflowX}}
  ${props => props.overflowY && {'overflow-y': props.overflowY}}
  ${props =>
    props.onClick && {
        cursor: 'pointer',
    }}
`;
const FlexBox = ({
                     position,
                     children,
                     direction,
                     justifyContent,
                     alignItem,
                     gap,
                     margin,
                     padding,
                     width,
                     height,
                     onClick,
                     iswrap,
                     border,
                     borderRadius,
                     borderBottom,
                     borderTop,
                     borderRight,
                     isCursor,
                     overflow,
                     background,
                     borderLeft,
                     overflowX,
                     overflowY,
                     flex
                 }) => {
    return (
        <Box
            flex={flex}
            position={position}
            direction={direction}
            justifyContent={justifyContent}
            alignItem={alignItem}
            gap={gap}
            margin={margin}
            padding={padding}
            width={width}
            height={height}
            onClick={onClick}
            iswrap={iswrap}
            border={border}
            borderRadius={borderRadius}
            borderBottom={borderBottom}
            borderTop={borderTop}
            borderRight={borderRight}
            borderLeft={borderLeft}
            isCursor={isCursor}
            overflow={overflow}
            overflowX={overflowX}
            overflowY={overflowY}
            background={background}>
            {children}
        </Box>
    );
};

export default FlexBox;
