import {getDayWithoutSeparator} from "../../utility/admin/timeFormat";
import {parseAmount, removeHyphen} from "../../utility/format";
import axios from "axios";

const httpBaumPns = axios.create({
    baseURL: 'https://baumpns.com/api/v1',
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 5000,
});

const httpReceiptPns = axios.create({
    baseURL: 'https://api.pullayo.com',
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 5000,
})

export const paymentViewList = (params, idx) => {
    const payload = {
        paymentDate: getDayWithoutSeparator(params.paymentDate),
        amount: parseAmount(params.amount),
        ...(idx !== 1 && params.authNumber ? { authNumber: params.authNumber } : {}),
        ...(idx !== 0 && params.cardNumber ? { cardNumber: removeHyphen(params.cardNumber) } : {}),
    };

    return httpBaumPns.post('/payment-list', payload);
};

export const paymentViewReceipt = (id) => {
    return httpReceiptPns.get(`/v1/oper/payment/recipt/${id}`);
}

export const paymentReceiptMail = (params) => {
    return httpReceiptPns.post('/v1/oper/payment/mail/recipt',params);
}
