import 'react-datepicker/dist/react-datepicker.css';
import 'styles/css/datepicker.css';
import { useState, forwardRef, useMemo, useRef } from 'react';
import DatePicker from 'react-datepicker';
import Button from 'components/admin/Button';
import {
  getDayCalendarFormat,
  getDayFormatYearMonth,
} from 'utility/admin/timeFormat';
import { ReactComponent as Calendar } from 'assets/icons/admin/datepicker/calendar.svg';
import { ReactComponent as Prev } from 'assets/icons/admin/datepicker/chevron_left.svg';
import { ReactComponent as Next } from 'assets/icons/admin/datepicker/chevron_right.svg';

import styled from 'styled-components';

function CustomDatePicker({ startDate, setStartDate, endDate, setEndDate, customClass='payView',isUnderLine }) {
  const calendar = useRef(null);
  const [inputStartDate, setInputStartDate] = useState(startDate || '');
  const [inputEndDate, setInputEndDate] = useState(endDate || '');

  // //   input
  const Input = forwardRef(({ value, onClick, customClass = ''}, ref) => (
    <div className={customClass ? 'datepicker_input_wrapper_payView' : 'datepicker_input_wrapper'} ref={ref} onClick={onClick}>
      <div className="__calendar_icon">
        <Calendar></Calendar>
      </div>
      <input type="text" value={formatDate} readOnly />
    </div>
  ));

  const InputUnderLine = forwardRef(({ value, onClick, customClass = ''}, ref) => (
      <div className={'datepicker_input_wrapper_payView_underline'} ref={ref} onClick={onClick}>
        <div className="flex flex-row absolute top-[10px] left-[10px]">
          <Calendar></Calendar>
        </div>
        <input type="text" value={formatDate} readOnly />
      </div>
  ));


  const renderDayContents = (day) => {
    return <div className="__day">{day}</div>;
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setInputStartDate(start);
    setInputEndDate(end);
  };

  // datepicker open시 초기화
  const onCalendarOpen = () => {
    setInputStartDate(startDate);
    setInputEndDate(endDate);
  };

  // 닫기 버튼
  const cancel = () => {
    setStartDate(startDate);
    setEndDate(endDate);
    calendar.current.setOpen(false);
  };
  // 적용 버튼
  const apply = () => {
    setStartDate(inputStartDate);
    setEndDate(inputEndDate);
    calendar.current.setOpen(false);
  };

  // input view date
  const formatDate = useMemo(() => {
    let format = `${startDate ? getDayCalendarFormat(startDate) : ''}`;
    if (endDate) format += ` ~ ${getDayCalendarFormat(endDate)}`;

    return format;
  }, [startDate, endDate]);
  const onCloseDatePicker = () => {
    if (!endDate) {
      setEndDate('');
      setStartDate('');
    }
  };
  return (
    <div className={'admin-datePicker'}>
      <DatePicker
        ref={calendar}
        selectsRange={true}
        dateFormat="yyyy.MM.dd"
        startDate={inputStartDate}
        endDate={inputEndDate}
        maxDate={new Date()}
        shouldCloseOnSelect={false}
        onCalendarOpen={() => {
          onCalendarOpen();
        }}
        onCalendarClose={() => onCloseDatePicker()}
        onChange={(update) => {
          onChange(update);
        }}
        customInput={!isUnderLine ? <Input customClass={customClass}/> : <InputUnderLine />}
        renderDayContents={renderDayContents}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <>
            <div className="move_date__wrapper">
              <Button
                className="prev_btn"
                onClick={() => {
                  decreaseMonth();
                }}>
                <Prev></Prev>
              </Button>
              <div className="date">{getDayFormatYearMonth(date)}</div>
              <Button
                className="next_btn"
                onClick={() => {
                  increaseMonth();
                }}>
                <Next></Next>
              </Button>
            </div>
            <div className="display_date__wrapper">
              <input
                type="text"
                value={
                  inputStartDate ? getDayCalendarFormat(inputStartDate) : ''
                }
                readOnly
              />
              -
              <input
                type="text"
                value={inputEndDate ? getDayCalendarFormat(inputEndDate) : ''}
                readOnly
              />
            </div>
          </>
        )}>
        {/* <div className="datepicker_bottom_btn__wrapper"> */}
        <DatepickerBottomBtnWrapper>
          <Button
            type={'outlined'}
            onClick={() => {
              cancel();
            }}>
            <span>취소</span>
          </Button>
          <Button
            style={{
              color: 'white',
            }}
            color={'#134575'}
            onClick={() => {
              apply();
            }}>
            <span>적용</span>
          </Button>
        </DatepickerBottomBtnWrapper>
        {/* </div> */}
      </DatePicker>
    </div>
  );
}

export { CustomDatePicker };

const DatepickerBottomBtnWrapper = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  > button {
    width: 142px;
    height: 44px;
  }
`;
