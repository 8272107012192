import React, {useState} from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

const TabNoNav = ({ items , idx, setIdx}) => {
    const handleTabOnClick = (index) => {
        setIdx(index);
    };
    return (
        <TabContainer
            className={
                'mt-[40px] mb-[70px] md:mb-[60px] sm:mb-[50px] xs:mb-[40px] fold:mb-[40px]'
            }>
            <div className={'flex flex-row  items-center justify-center gap-[20px]'}>
                {items.map((item,index) => {
                    return (
                        <div
                            key={item.title}
                            onClick={() => handleTabOnClick(index)}
                            className={`max-w-[290px] h-[60px] xs:h-[44px] fold:h-[44px] w-full flex items-center justify-center rounded-[15px] xs:rounded-[10px] fold:rounded-[10px] text-bodyNormal sm:text-bodySmall xs:text-bodyXSmall fold:text-bodyXXXSmall cursor-pointer fold:whitespace-normal fold:break-keep fold:text-center ${
                                idx === item.link
                                    ? 'bg-main text-white'
                                    : 'bg-darkF9 text-dark1A'
                            }`}>
                            {item.title}
                        </div>
                    );
                })}
            </div>
        </TabContainer>
    );
};

export default TabNoNav;

const TabContainer = styled.div`
	& * {
		transition: all 0.4s;
	}
`;
