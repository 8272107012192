import { PullAyoSvg } from 'assets/export/PullAYoSvg';
import FlexBox from "./Flexbox";
import Text from "./Text";
import {appendCommas, convertBusinessNumber, formatDateTime} from "../../utility/format";
import {useLayoutEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {paymentViewReceipt} from "../../api/admin/paymentView";
import ReceiptSelectBox from "../admin/ReceiptSelectBox";
import {ReceiptCancelImage2} from "../../assets/export/ReceiptCancelImage2";
import {ReceiptMessageImage} from "../../assets/export/ReceiptMessageImage";
import ReceiptShareModal from "../modal/ReceiptShareModal";
import {ReceiptDownImage} from "../../assets/export/ReceiptDownImage";
import html2canvas from 'html2canvas';

export const getTradeStateForReceipt = state => {
    switch (state) {
        case '0':
            return '승인';
        case '1':
            return '실패';
        case '2':
            return '취소';
        case '3':
            return '부분취소 ';

        default:
            return '-';
    }
};

const Container = styled.div`
  width: 352px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 20px 16px 0px;
  // height: ${props=>props.isLarge ? 'max-content' : '825px'};
  justify-content: flex-start;
`;

const BackGroundContainer = styled.div`
  width: 424px;
  height: 100vh;
  background-color: #ebeef2;

  padding: 13px 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

const Circle = styled.div`
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%; /* 동그라미를 만들기 위한 테두리 반경 설정 */
  background: radial-gradient(at 50% 120%, #fff, #fff, #c0c0c0); /* 그라데이션 설정 */
`;

const HeadContainer = styled.div`
  display: flex;
  background: #008bff;
  padding: 4px 0;
`;

const Title = styled.div`
  width: 100%;
  height: 26px;
  border: 1px solid #ebeef2;
  background-color: #f4f6f9;
  margin-top: 6.5px;
  margin-bottom: 6.5px;
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

const Content = styled.div`
  min-height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: ${({alignItem}) => (alignItem ? alignItem : 'center')};
  padding: 0 10px;
  padding-top: ${({isMarginTop}) => isMarginTop && '3px'};
  letter-spacing: 0px;
  font-variant-numeric: tabular-nums;
`;

const SubContent = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
`;

const BrTakeDiv = styled.div`
  font-size: 11px;
  color: #394452;
  text-align: right;
  line-height: 140%;
`;

const PrdNmDiv = styled.div`
  white-space: pre-wrap;
  line-height: 140%;
  text-align: right;
  width: 250px;
  font-size: 11px;
  color: #394452;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #dadee3;
  cursor: pointer;
`;

const Receipt = () => {
    // const userData = useRecoilValue(recoilUserState);
    const route = new URLSearchParams(window.location.search);
    const [receiptData, setReceiptData] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [imageData, setImageData] = useState();
    const [isCash, setIsCash] = useState(false);

    //헤더 셀렉박스 옵션
    const [selectOption, setSelectOption] = useState();
    const [selectValue, setSelectValue] = useState();
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [cancelDate, setCancelDate] = useState([]);
    const [isCancelImage, setIsCancelImage] = useState(false);
    const [isSelect, setIsSelect] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // 결제정보
    const [paymentData, setPaymentData] = useState();
    // 결제금액정보
    const [payAmount, setPayAmount] = useState([]);

    const [isUserTaxState,setIsUserTaxState] = useState('0');
    //상점정보
    const [storeData, setStoreData] = useState([]);

    //공급자정보
    const supplyData = [
        {title: '상호', value: '바움피앤에스 주식회사'},
        {title: '대표자', value: '김문수'},
        {title: '사업자등록번호', value: '836-87-00147'},
        {title: '전화번호', value: '1833-6199'},
        {title: '주소', value: '인천시 연수구 인천타워대로 323<br>송도 센트로드 2907~09호', isAddress: true},
    ];

    const ref = useRef();
    const onClickPirint = () => {
        window.print();
    };

    useLayoutEffect(() => {
        if (route.get('tid')) {
            getData();
        }
    }, []);

    const getData = () => {
        paymentViewReceipt(route.get('tid'))
            .then((res) => {
                if (Object.keys(res.data.data).length !== 0) {
                    const detail = res.data.data.select_payment_detail;
                    const gidData = res.data.data.gid_detail;
                    const midData = res.data.data.mid_detail;
                    const paymentHistory = res.data.data.payment_history;
                    if (paymentHistory && paymentHistory.length > 0) {
                        const isAllCancel =
                            paymentHistory[0].cnclBalance == 0 && paymentHistory[0].payment_state == 3;
                        const paymentMtd = paymentHistory[0].payment_method;
                        const buyerName =
                            paymentMtd == '0'
                                ? '오*****점'
                                : paymentMtd == '6'
                                    ? '정*****제'
                                    : '*****';
                        const webAddress = midData.web_address || '';
                        const orderId =
                            paymentMtd == '6'
                                ? 'BP' + (paymentHistory[0].payment_number?.slice(-10) || '*****')
                                : paymentHistory[0].payment_number?.slice(-10) || '*****';
                        const isCash =
                            !Boolean(detail.approval_number) || detail.approval_number === '00000000';

                        setIsCash(isCash);
                        setIsUserTaxState(midData.user_tax_state);

                        setPaymentData([
                            { title: '구매자', value: detail.user_name || buyerName },
                            { title: '상품명', value: detail.item_name || orderId },
                            { title: '카드번호', value: isCash ? '-' : detail.card_number },
                            { title: '카드종류', value: detail.buy_company_name || '-' },
                            {
                                title: '할부개월',
                                value:
                                    isCash
                                        ? '-'
                                        : detail.monthly_installment_plan === '00' ||
                                        detail.monthly_installment_plan === '0'
                                            ? '일시불'
                                            : Number(detail.monthly_installment_plan) + '개월' || '-',
                            },
                            { title: '승인번호', value: !isCash ? detail.approval_number : '-' },
                            {
                                title: '승인일시',
                                value: paymentHistory[paymentHistory.length - 1].approval_date
                                    ? formatDateTime(paymentHistory[paymentHistory.length - 1].approval_date)
                                    : '-',
                            },
                        ]);

                        setStoreData([
                            { title: '상호', value: gidData.company_name || '-' },
                            { title: '대표자', value: gidData.representative || '-' },
                            { title: '사업자등록번호', value: convertBusinessNumber(gidData.business_number) || '-' },
                            { title: '전화번호', value: gidData.tel || '-' },
                            {
                                title: '주소',
                                value: gidData.address + '<br>' + gidData.detailed_address || '-',
                                isAddress: true,
                            },
                            { title: 'URL', value: webAddress.includes('baumpns.com') ? '' : webAddress },
                        ]);

                        setPaymentHistory([
                            ...paymentHistory,
                            ...(isAllCancel ? [paymentHistory[0]] : []),
                        ]);

                        let count = paymentHistory.length;
                        setSelectOption(
                            paymentHistory.map((item, idx) => {
                                if (item.payment_state == '3') {
                                    count -= 1;
                                }
                                return {
                                    label: `${getTradeStateForReceipt(item.payment_state)}${
                                        item.payment_state == '3' ? count + '차' : ''
                                    }`,
                                    value: idx,
                                };
                            })
                        );

                        setSelectValue(0);
                        setIsSelect(paymentHistory.length !== 1);
                        setReceiptData(true);
                    } else {
                        // paymentHistory가 없을 때
                        const paymentMtd = midData.payment_method;
                        const buyerName =
                            paymentMtd == '0'
                                ? '오*****점'
                                : paymentMtd == '6'
                                    ? '정*****제'
                                    : '*****';
                        const webAddress = midData.web_address || '';
                        const orderId =
                            paymentMtd == '6'
                                ? 'BP' + (detail.payment_number?.slice(-10) || '*****')
                                : detail.payment_number?.slice(-10) || '*****';

                        setPaymentData([
                            { title: '구매자', value: detail.user_name || buyerName },
                            { title: '상품명', value: detail.item_name || orderId },
                            { title: '카드번호', value: detail.card_number || '-' },
                            { title: '카드종류', value: detail.buy_company_name || '-' },
                            {
                                title: '할부개월',
                                value:
                                    detail.monthly_installment_plan === '00' ||
                                    detail.monthly_installment_plan === '0'
                                        ? '일시불'
                                        : Number(detail.monthly_installment_plan) + '개월' || '-',
                            },
                            { title: '승인번호', value: detail.approval_number || '-' },
                            {
                                title: '승인일시',
                                value: detail.approval_date ? formatDateTime(detail.approval_date) : '-',
                            },
                        ]);

                        setStoreData([
                            { title: '상호', value: midData.company_name || '-' },
                            { title: '대표자', value: midData.representative || '-' },
                            { title: '사업자등록번호', value: convertBusinessNumber(gidData.business_number) || '-' },
                            { title: '전화번호', value: midData.tel || '-' },
                            {
                                title: '주소',
                                value: gidData.address + '<br>' + gidData.detailed_address || '-',
                                isAddress: true,
                            },
                            { title: 'URL', value: webAddress.includes('baumpns.com') ? '' : webAddress },
                        ]);

                        // 부가세율 (10%로 가정)
                        const vatRatio = 0.1;
                        // 공급가액을 올림 처리
                        const taxAbleAmount = Math.ceil(detail.amount / (1 + vatRatio));
                        // 부가세 계산
                        const vatAmount = detail.amount - taxAbleAmount;

                        const isApproval =
                            paymentHistory &&
                            paymentHistory[selectValue] &&
                            paymentHistory[selectValue].payment_state === '0'
                                ? true
                                : false;

                        setPayAmount([
                            {
                                title: '공급가액',
                                value: isUserTaxState == 0 ? taxAbleAmount + vatAmount : taxAbleAmount, isApproval: isApproval
                            },
                            { title: '세액', value: isUserTaxState == 0 ? '0' : vatAmount ,isApproval: isApproval},
                            { title: '합계', value: detail.amount, isTotal: true, isApproval: true },
                        ]);

                        setPaymentHistory([...detail]);
                        setCancelDate([]);
                        setIsSelect(false);
                        setReceiptData(true);
                        setSelectValue(0);
                    }
                } else {
                    setReceiptData(false);
                }
            })
            .catch((err) => {
                console.error(err);
                setReceiptData(false);
            });
    };


    const onClickCapture = () => {
        html2canvas(document.body).then(canvas => {
            const data = canvas.toDataURL('image/png');
            setImageData(data);
        });
    };
    const onChangeSelect = (e, name) => {
        setSelectValue(e.value);
    };

    const onClickCaptureDown = () => {
        html2canvas(document.body)
            .then(canvas => {
                const dataURL = canvas.toDataURL('image/png');

                const link = document.createElement('a');
                link.href = dataURL;
                link.download = '전표.png'; // 원하는 파일 이름

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(error => {
                console.error("캡쳐 에러:", error);
            });
    };

    useLayoutEffect(() => {
        if (paymentHistory && paymentHistory[selectValue]) {
            const isApproval = paymentHistory[selectValue].payment_state == '0';

            // 거래금액
            const amount =
                paymentHistory[selectValue].payment_state == '0' ||
                (paymentHistory[selectValue].payment_state == '3' && paymentHistory[selectValue].cnclBalance == 0 && selectValue + 1 == paymentHistory.length)
                    ? paymentHistory[selectValue].amount
                    : paymentHistory[selectValue].cancel_amount; // 1,000,000원

            // 부가세율 (10%로 가정)
            const vatRatio = 0.1;

            // 공급가액을 올림 처리
            const taxAbleAmount = Math.ceil(amount / (1 + vatRatio));

            // 부가세 계산
            const vatAmount = amount - taxAbleAmount;
            setPayAmount([
                {title: '공급가액', value: isUserTaxState == 1 ? taxAbleAmount + vatAmount : taxAbleAmount , isApproval: isApproval},
                {title: '세액', value: isUserTaxState == 1 ? '0' : vatAmount, isApproval: isApproval},
                {title: '합계', value: amount, isTotal: true, isApproval: isApproval},
            ]);
            if (!isApproval) {
                const arrayLength = paymentHistory.length - 1;
                const approvalDate = paymentHistory[arrayLength].approval_date;
                setCancelDate([
                    {
                        title: '취소일시',
                        value: paymentHistory[selectValue].approval_date == approvalDate ? paymentHistory[selectValue].registration_date : formatDateTime(paymentHistory[selectValue].approval_date),
                    },
                ]);
                if (paymentHistory[selectValue].payment_state == '2' || paymentHistory[selectValue].payment_state == '3') {
                    setIsCancelImage(true);
                } else {
                    setIsCancelImage(false);
                }
            } else {
                setCancelDate([]);
                setIsCancelImage(false);
            }
        }
    }, [selectValue, paymentData]);


    const itemNameRef = useRef(null);
    const [isLarge, setIsLarge] = useState(false);

    useLayoutEffect(() => {
        setTimeout(() => {
            if (itemNameRef?.current) {
                const height = itemNameRef.current.clientHeight;
                if (height > 152) {
                    setIsLarge(true);
                }
            }
        }, 100);
    }, [paymentData]);

    return (
        <>
            {receiptData ? (
                isLoading &&
                paymentData && (
                    <>
                        <BackGround height={`794px`}>
                            <Container isLarge={isLarge}>
                                <>
                                    {isCancelImage && <ReceiptCancelImage2 />}
                                    {/* 로고 및 아이콘 */}
                                    <FlexBox margin={'0px 0 16px 0'} justifyContent={'space-between'} alignItem={'flex-end'}>
                                        <PullAyoSvg isDark width="126px" height="34px" />

                                        <FlexBox gap={5} justifyContent={'flex-end'} width={'50px'}>
                                            {/*{userData.isLogin && (*/}
                                                <>
                                                    <IconContainer>
                                                        <ReceiptMessageImage
                                                            onClick={() => {
                                                                setIsOpen(true);
                                                                onClickCapture();
                                                            }}
                                                        />
                                                    </IconContainer>
                                                    <IconContainer>
                                                        <ReceiptDownImage onClick={onClickCaptureDown} />
                                                        {/*<ReceiptPrintImage onClick={onClickCaptureDown} />*/}
                                                    </IconContainer>
                                                </>
                                            {/*)}*/}
                                        </FlexBox>
                                    </FlexBox>

                                    {/* 헤더 및 셀렉박스 */}
                                    <HeadContainer>
                                        <FlexBox height={'30px'} alignItem={'center'} justifyContent={'space-between'} padding="0 10px ">
                                            <Text color={'#FBFCFF'} size={14}>
                                                {isCash ? '' : '신용카드'} 매출전표
                                            </Text>
                                            {isSelect && <ReceiptSelectBox width={'92px'} option={selectOption} value={selectValue} onChange={onChangeSelect} name="selectValue" />}
                                        </FlexBox>
                                    </HeadContainer>

                                    {/* 결제정보 */}
                                    <Title>
                                        <Text size={11} color={'#2C3A4B'}>
                                            결제정보
                                        </Text>
                                    </Title>
                                    {paymentData.map((item, idx) => {
                                        return (
                                            <Content key={idx}>
                                                <Text color={'#394452'} size={11} lineheight={'140%'}>
                                                    {item.title}
                                                </Text>
                                                {item.title ==='상품명' ?
                                                    <PrdNmDiv ref={itemNameRef}>
                                                        {item.value}
                                                    </PrdNmDiv>
                                                    :
                                                    <Text color={'#394452'} size={11}>
                                                        {item.value}
                                                    </Text>
                                                }
                                            </Content>
                                        );
                                    })}
                                    {cancelDate &&
                                        cancelDate.map((item, idx) => {
                                            return (
                                                <Content key={idx}>
                                                    <Text color={'#394452'} size={11} lineheight={'140%'}>
                                                        {item.title}
                                                    </Text>
                                                    <Text color={'#394452'} size={11} lineheight={'140%'}>
                                                        {item.value}
                                                    </Text>
                                                </Content>
                                            );
                                        })}

                                    {/* 결제금액정보  */}
                                    <Title>
                                        <Text size={11} color={'#2C3A4B'}>
                                            결제금액정보
                                        </Text>
                                    </Title>
                                    {payAmount.map((item, idx) => {
                                        const isTotal = item.isTotal;
                                        return (
                                            <Content key={idx} alignItem={'center'}>
                                                <Text color={'#394452'} size={11} lineheight={'140%'}>
                                                    {item.title}
                                                </Text>
                                                <Text color={isTotal ? '#EF4452' : '#394452'} size={isTotal ? 13 : 11} weight={isTotal && 500} isThrough={!item.isApproval} lineheight={'140%'}>
                                                    {appendCommas(item.value) + ' 원'}
                                                </Text>
                                            </Content>
                                        );
                                    })}

                                    {/* 상점정보  */}
                                    <Title>
                                        <Text size={11} color={'#2C3A4B'}>
                                            상점정보
                                        </Text>
                                    </Title>
                                    {storeData.map((item, idx) => {
                                        return (
                                            <Content key={idx} alignItem={item.isAddress ? 'flex-start' : 'center'} isMarginTop={item.isAddress}>
                                                <Text color={'#394452'} size={11} weight={400} lineheight={'140%'}>
                                                    {item.title}
                                                </Text>
                                                {item.isAddress ? (
                                                    <BrTakeDiv dangerouslySetInnerHTML={{__html: item.value}} />
                                                ) : (
                                                    <Text color={'#394452'} size={11} weight={400} lineheight={'140%'}>
                                                        {item.value}
                                                    </Text>
                                                )}
                                            </Content>
                                        );
                                    })}

                                    {/* 공급자정보  */}
                                    <Title>
                                        <Text size={11} color={'#2C3A4B'}>
                                            공급자정보
                                        </Text>
                                    </Title>
                                    {supplyData.map((item, idx) => {
                                        return (
                                            <Content key={idx} alignItem={item.isAddress ? 'flex-start' : 'center'} isMarginTop={item.isAddress}>
                                                <Text color={'#394452'} size={11} weight={400} lineheight={'140%'}>
                                                    {item.title}
                                                </Text>
                                                {item.isAddress ? (
                                                    <BrTakeDiv dangerouslySetInnerHTML={{__html: item.value}} />
                                                ) : (
                                                    <Text color={'#394452'} size={11} weight={400} lineheight={'140%'}>
                                                        {item.value}
                                                    </Text>
                                                )}
                                            </Content>
                                        );
                                    })}
                                    <FlexBox borderTop={'1px solid #EBEEF2'} margin={'8.5px 0 0 0 '} padding={'16px 0 16px 0 '}>
                                        <Text color="#545D69" size={10} iswrap={true} lineheight={'12px'}>
                                            * 부가가치세법 제 46조 3항에 따라 신용카드 매출전표도 매입계산서로 사용할 수 있으며 부가가치세법 제 33조 2항에 따라 별도의 세금계산서를 교부하지 않습니다.
                                        </Text>
                                    </FlexBox>
                                </>
                            </Container>
                        </BackGround>
                        {/*<div style={{display: 'none'}}>{<ReceiptPrint ref={ref} />}</div>*/}
                        {isOpen && <ReceiptShareModal isOpen={isOpen} setIsOpen={setIsOpen} imageData={imageData} />}
                    </>
                )
            ) : (
                <FlexBox justifyContent={'flex-start'} alignItem={'flex-start'}>
                    <Text textAlign={'left'} weight={400} size={12}>
                        조회하신 TID에 대한 거래내역을 찾을 수 없습니다.
                    </Text>
                </FlexBox>
            )}
        </>
    );
};

const Position = styled.div`
  width: 424px;
  position: absolute;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  gap: 24px;
  padding: 0px 10px;
`;
const BackGround = ({height, children}) => {
    return (
        <BackGroundContainer height={height}>
            <Position>
                {Array.from({length: 21}, (v, i) => i).map((item, idx) => (
                    <FlexBox justifyContent={'space-between'} key={idx}>
                        <Circle />
                        <Circle />
                    </FlexBox>
                ))}
            </Position>
            {children}
        </BackGroundContainer>
    );
};

export default Receipt;
