
export const PAYVIEWabItems = [
    {
        title: '승인번호',
        link:0,
    },
    {
        title: '카드번호',
        link:1,
    },
];

export const mPAYVIEWabItems = [
    {
        title: '승인번호',
        link:0,
    },
    {
        title: '카드번호',
        link:1,
    },
];

export const payViewListItem = [
    {
        type: 'text',
        name: 'authNumber',
        label: '승인번호',
        placeholder:'숫자만 입력해 주세요',
    },
    {
        type: 'date',
        name: 'paymentDate',
        label: '결제일자',
    },
    {
        type: 'payment',
        name: 'amount',
        label: '결제금액',
        placeholder:'숫자만 입력해 주세요',
    },

];

export const payViewCardListItem = [
    {
        type: 'text',
        name: 'cardNumber',
        label: '카드번호',
        placeholder:'- 제외하고 숫자만 입력해 주세요.',
    },
    {
        type: 'date',
        name: 'paymentDate',
        label: '결제일자',
    },
    {
        type: 'payment',
        name: 'amount',
        label: '결제금액',
        placeholder:'숫자만 입력해 주세요',
    },

];
