import React, {memo, useCallback, useContext, useState} from 'react';
import TextField from './TextField';
import Button from './Button';
import {ModalContext} from "../modal/modalContext";
import AccordionList from "../web/accordions/AccordionList";
import styled from "@emotion/styled";
import {CustomDayDatePicker} from "./DayDatePicker";



const TextUnderLine = styled.span`
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  color: #0081ff;
  text-decoration: underline !important;
  cursor: pointer;
`;

const PayViewUnderLine = ({
                         className = '',
                         state,
                         searchItems,
                         onClickSearch,
                         onChange,
                            modalTitle,
    modalContents,
    update
                     }) => {
    const handleOnChanges = ({ name, value }) => {
        let numericValue = value.replace(/\D/g, '');

        if (name === 'cardNumber') {
            numericValue = numericValue.slice(0, 16);
        }

        if (name === 'authNumber') {
            numericValue = numericValue.slice(0, 8);
        }
        onChange({ name, value: numericValue });
    };
    const datePicker = (item) => (
        <div className={'flex flex-col pb-[20px]'} key={item.name}>
            <div className={'pb-[10px]'}>{item.label}</div>
            <div>
                <CustomDayDatePicker
                    paymentDate={state.paymentDate}
                    setPaymentDate={(value) => onChange({ value: value, name: 'paymentDate' })}
                    isUnderLine
                />
            </div>
        </div>
    );
    const genericInput = (item) => (
        <div className={'flex flex-col pb-[20px]'} key={item.name}>
            <div>{item.label}</div>
            <div className={''}>
                <TextField
                    height="42px"
                    width="420px"
                    placeholder={item.placeholder || '검색어를 입력해주세요.'}
                    name={item.name}
                    value={state[item.name] || ''}
                    onChange={({ target: { value } }) =>
                        handleOnChanges({ name: item.name, value })
                    }
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            onClickSearch();
                        }
                    }}
                    isUnderLine
                />
            </div>
        </div>
    );

    const { openModal, closeModal } = useContext(ModalContext);

    const handleAgreedModalOpen = useCallback(()=> {
        openModal(modalTitle, {
                confirm: () => closeModal()
            },
            { type: 'agree' },modalContents,
        )
    })


    return (
        <div className={'flex flex-col'}>
                <div className={''}>
                    {searchItems?.map((item) => {
                        switch (item.type) {
                            case 'date':
                                return datePicker(item);
                            case 'text':
                                return genericInput(item);
                            case 'payment':
                                return genericInput(item);
                            case 'card':
                                return genericInput(item);
                            default:
                                return null;
                        }
                    })}


                </div>


            <div
                className={'flex flex-col w-full fold:w-[300px] ss:w-[320px] xs:w-[350px] smMd:w-[380px] sm:w-[480px] md:w-[600px] justify-center items-center gap-[10px]'}
            >
                <div className={'text-bodyXXSmall items-center'}>
                    <TextUnderLine onClick={handleAgreedModalOpen}>개인정보 수집 및 이용약관</TextUnderLine> 동의
                </div>

                <Button
                    width={'240px'}
                    height={'46px'}
                    color={'admin_main'}
                    style={{
                        color: 'white',
                    }}
                    onClick={update}
                >
                    <span>동의 후 조회</span>
                </Button>
            </div>
        </div>
    );
};

export default memo(PayViewUnderLine);
