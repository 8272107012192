export const bizNoFormatter = (num) => {
  if (!num) return '';
  return num.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
};

export const formatPhoneNumber = (input) => {
  if (!input) return '';
  const cleanInput = input.replaceAll(/[^0-9]/g, '');

  let result = '';
  const length = cleanInput.length;
  if (length === 8) {
    result = cleanInput.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else if (cleanInput.startsWith('02') && (length === 9 || length === 10)) {
    result = cleanInput.replace(/(\d{2})(\d{3,4})(\d{4})/, '$1-$2-$3');
  } else if (!cleanInput.startsWith('02') && (length === 10 || length === 11)) {
    result = cleanInput.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
  } else {
    result = cleanInput;
  }
  return result;
};

// 1000 자리마다 , 붙이는 함수.
export const appendCommas = value => {
  if (value === null || value === undefined) return '';
  var formattedNumber = value.toString().replace(/[^\d-]/g, '');
  if (formattedNumber === 0 || formattedNumber === '0') return '0';
  if (!formattedNumber && formattedNumber !== 0) return '';

  return parseInt(formattedNumber, 10)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function parseAmount(amountStr) {
  try {
    // 입력이 문자열이 아니면 예외 발생
    if (typeof amountStr !== 'string') {
      throw new Error('Input must be a string');
    }
    // 모든 콤마 제거
    const cleaned = amountStr.replace(/,/g, '');
    // 숫자로 변환
    const num = Number(cleaned);
    // 변환된 값이 유효한 숫자가 아니면 예외 발생
    if (isNaN(num)) {
      throw new Error('Result is not a valid number');
    }
    return num;
  } catch (error) {
    return null;
  }
}

/**
 * 사업자번호 형식으로 변환
 * @return Date
 */
export function convertBusinessNumber(value) {
  if(value === '-') return '-';
  if (!value) return '';
  // Remove all non-digit characters from the phone number
  const cleaned = value.replace(/\D/g, '').replace(/[^0-9\.]+/g, '');

  // Check if the cleaned phone number is 10 digits long
  if (cleaned.length === 10) {
    // If so, format it as (123) 456-7890
    return cleaned.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
  } else if (cleaned.length > 10) {
    return cleaned.slice(0, 10).replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
  } else {
    // If the phone number is not 10 or 11 digits long, return it unchanged
    return cleaned;
  }
}
// 카드번호 형식 변환
export function formatCardNumber(cardNumber) {
  // 숫자 이외의 문자를 모두 제거
  const digits = cardNumber.replace(/\D/g, '');
  // 4자리씩 그룹으로 나누고 하이픈으로 연결
  return digits.replace(/(\d{4})(?=\d)/g, '$1-');
}

// 모든 하이픈을 제거하여 반환
export function removeHyphen(cardNumber) {

  return cardNumber.replace(/-/g, '');
}

/**
 * yyyy-MM-dd hh:mm:ss 형식으로 변환하는 함수
 * @return Date
 */
export function formatDateTime(dateString) {
  if (!dateString) return '-';
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);
  const hour = dateString.slice(8, 10);
  const minute = dateString.slice(10, 12);
  const second = dateString.slice(12, 14);

  const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  return formattedDate;
}
