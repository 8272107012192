import 'react-datepicker/dist/react-datepicker.css';
import 'styles/css/datepicker.css';
import {useState, forwardRef, useMemo, useRef, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import Button from 'components/admin/Button';
import {
    getDayCalendarFormat,
    getDayFormatYearMonth,
} from 'utility/admin/timeFormat';
import { ReactComponent as Calendar } from 'assets/icons/admin/datepicker/calendar.svg';
import { ReactComponent as Prev } from 'assets/icons/admin/datepicker/chevron_left.svg';
import { ReactComponent as Next } from 'assets/icons/admin/datepicker/chevron_right.svg';
import styled from 'styled-components';
import ko from 'date-fns/locale/ko';


function CustomDayDatePicker({ paymentDate,setPaymentDate, customClass='payView',isUnderLine }) {
    const calendar = useRef(null);
    const [selectedDate, setSelectedDate] = useState(paymentDate || null);


    // //   input
    const Input = forwardRef(({ value, onClick, customClass = ''}, ref) => (
            <div className={customClass ? 'datepicker_input_wrapper_payView' : 'datepicker_input_wrapper'} ref={ref} onClick={onClick}>
                <div className="__calendar_icon">
                    <Calendar></Calendar>
                </div>
                <input type="text" value={formatDate} readOnly />
            </div>
    ));

    const InputUnderLine = forwardRef(({ value, onClick, customClass = ''}, ref) => (
        <div className={'datepicker_input_wrapper_payView_underline'} ref={ref} onClick={onClick}>
            <div className="flex flex-row absolute top-[10px] left-[10px]">
                <Calendar></Calendar>
            </div>
            <input type="text" value={formatDate} readOnly />
        </div>
    ));

    useEffect(() => {
        setSelectedDate(paymentDate || new Date());
    }, [paymentDate]);

    const renderDayContents = (day,date) => {
        const isSelected =
            selectedDate &&
            date.getDate() === selectedDate.getDate() &&
            date.getMonth() === selectedDate.getMonth() &&
            date.getFullYear() === selectedDate.getFullYear();

        return  <div className={`__day ${isSelected ? 'selected' : ''}`}>
                    {day}
                </div>
    };

    // 단일 날짜 선택 onChange 핸들러
    const onChange = (date) => {
        setSelectedDate(date);
        setPaymentDate(date);
        calendar.current.setOpen(false);
    };

    // datepicker open시 초기화
    const onCalendarOpen = () => {
        setSelectedDate(selectedDate);
        setSelectedDate(selectedDate);
    };


    // input view date

    // 날짜 포맷 (선택된 날짜를 포맷팅)
    const formatDate = useMemo(() => {
        return selectedDate ? getDayCalendarFormat(selectedDate) : '';
    }, [selectedDate]);


    //
    // // 취소 버튼: 선택 취소
    // const cancel = () => {
    //     calendar.current.setOpen(false);
    // };
    // // 적용 버튼
    // const apply = () => {
    //     setPaymentDate(selectedDate);
    //     calendar.current.setOpen(false);
    // };
    //
    //
    // const onCloseDatePicker = () => {
    //     if (!paymentDate) {
    //         setPaymentDate('');
    //     }
    // };


    return (
        <div className={'admin-datePicker'}>
            <DatePicker
                ref={calendar}
                selected={selectedDate}
                dateFormat="yyyy.MM.dd"
                locale={ko}
                maxDate={new Date()}
                shouldCloseOnSelect={false}
                onCalendarOpen={() => {
                    onCalendarOpen();
                }}
                onChange={(update) => {
                    onChange(update);
                }}
                customInput={!isUnderLine ? <Input customClass={customClass}/> : <InputUnderLine />}
                renderDayContents={renderDayContents}
                renderCustomHeader={({
                                         date,
                                         changeYear,
                                         changeMonth,
                                         decreaseMonth,
                                         increaseMonth,
                                         prevMonthButtonDisabled,
                                         nextMonthButtonDisabled,
                                     }) => (
                    <>
                        <div className="move_date__wrapper">
                            <Button
                                className="prev_btn"
                                onClick={() => {
                                    decreaseMonth();
                                }}>
                                <Prev></Prev>
                            </Button>
                            <div className="date">{getDayFormatYearMonth(date)}</div>
                            <Button
                                className="next_btn"
                                onClick={() => {
                                    increaseMonth();
                                }}>
                                <Next></Next>
                            </Button>
                        </div>
                    </>
                )}>

            </DatePicker>
        </div>
    );
}

export { CustomDayDatePicker };
