import Section from "../../../layout/web/Section";
import ServiceSectionTypography from "../../../components/web/texts/ServiceSectionTypography";
import React, {useEffect, useState} from "react";
import {useMediaQuery} from "@mui/material";
import TabNoNav from "../../../components/web/tabs/TabNoNav";
import {
    mPAYVIEWabItems,
    PAYVIEWabItems,
    payViewCardListItem,
    payViewListItem
} from "../../../utility/web/items/paymentView";
import PayViewList from "../../../components/admin/payViewList";
import PayViewUnderline from "../../../components/admin/payViewUnderline";
import {appendCommas, formatCardNumber} from "../../../utility/format";
import Table from "../../../components/admin/Table";
import {paymentViewColumn, questionColumn} from "../../../utility/admin/tableColumn";
import {paymentViewList} from "../../../api/admin/paymentView";
import {Toast} from "../../../components/toast";
import {getDayCalendarFormat} from "../../../utility/admin/timeFormat";



const ModalTitle = () => {
    return (
        <div className={'text-left text-bodySmall pb-[10px] ml-[-10px]'}>
            개인정보 수집 및 이용 약관
        </div>
    )
}

const ModalContents = () => {
    return (
        <div className={'text-bodyXSmall text-left font-light ml-[-10px]'}>
            <div>
                주식회사 바움피앤에스(이하 "회사")는 이용자의 <br className={'smHidden:hidden'}/> 개인
                정보를 중요시하며, 개인정보보호법 관련 법령에 <br className={'smHidden:hidden'}/>
                의거한 <br className={'xs:hidden ss:hidden fold:hidden'}/>'개인정보의 수집 및 이용에 대한 동의'를 <br className={'md:hidden lg:hidden max:hidden'}/> 정하여
                이용자 권익을 보호하고 있습니다.
            </div>

            <div className={'text-bodySmall mt-[20px]'}>
                1. 개인정보의 수집 이용 목적
            </div>

            <div>
                전자금융거래서비스 제공에 관한 결제 정보 등 개인<br className={'smHidden:hidden'}/> 정보 수집(결제내역 조회 및 거래명세표 출력)
                <br className={'smHidden:hidden'}/>
                <br className={'xs:hidden ss:hidden fold:hidden'}/> ※ 회사는 개인정보를 위의 목적으로 처리하며, 동의
                <br className={'smHidden:hidden'}/>
                받은 목적 이외의 용도로 처리하지 않습니다.
            </div>

            <div className={'text-bodySmall mt-[20px]'}>
                2. 수집 이용하는 개인정보 항목
            </div>

            <div>
                본인의 결제내역 확인을 위한 정보 <br/>
                -카드번호, 승인번호, 거래금액, 결제일자
            </div>

            <div className={'text-bodySmall mt-[20px]'}>
                3. 개인정보의 보유 및 이용기간
            </div>

            <div>
                결제 서비스 이용내역의 조회 목적이 달성되면  <br className={'sm:hidden md:hidden lg:hidden max:hidden'}/> 지체없이 파기 <br/>
                <br/>
                이용자는 회사의 개인정보 수집 이용 동의를 거부할 <br className={'sm:hidden md:hidden lg:hidden max:hidden'}/> 권리가 있습니다. 다만, 개인정보의 수집 이용 동의 <br className={'sm:hidden md:hidden lg:hidden max:hidden'}/> 를    <br className={'xs:hidden ss:hidden fold:hidden'}/> 거부할
                경우 전자결제 내역 확인 서비스의 이용이 <br className={'sm:hidden md:hidden lg:hidden max:hidden'}/> 제한 될 수 있음을 알려드립니다.
            </div>

        </div>
    )
}
const PaymentView = () => {
    const xs = useMediaQuery('(max-width: 600px)');
    const [idx, setIdx] = useState(0);

    const [searchForm, setSearchForm] = useState({
        authNumber:'',
        cardNumber:'',
        paymentDate: '',
        amount:0,
    });

    const [data,setData] = useState([]);

    const handleOnChange = ({ value, name }) => {
        setSearchForm((prev) => {
            let newValue = value;
            if (name === 'amount') {
                newValue = appendCommas(value);
            } else if (name === 'cardNumber') {
                newValue = formatCardNumber(value);
            }
            return {
                ...prev,
                [name]: newValue,
            };
        });
    };

    const update = async () => {
        if(idx == 0 && (!searchForm.authNumber || searchForm.authNumber.trim() === '')) {
            return Toast.error('승인번호 값이 비어있습니다.')
        }

        if(idx == 1 && (!searchForm.cardNumber || searchForm.cardNumber.trim() === '')) {
            return Toast.error('카드번호 값이 비어있습니다.')
        }

        if((!searchForm.paymentDate || searchForm.paymentDate === '')) {
            return Toast.error('결제일자 값이 비어있습니다.')
        }

        if((!searchForm.amount || searchForm.amount === '')) {
            return Toast.error('결제금액 값이 비어있습니다.')
        }

        try {
            const res = await paymentViewList(searchForm,idx);
            setData(res);
            return Toast.success('조회 되었습니다.');
        } catch (e) {
            return Toast.error('오류가 발생하였습니다.');
        }
    };

    const onClickReceipt2 = (id) => {
            window.open(`/chit?tid=${id}`, '_blank', `width=424,height=${860}`);
    };

    useEffect(() => {
        setSearchForm({
            authNumber: '',
            cardNumber: '',
            paymentDate: new Date(),
            amount: 0,
        });
        setData([]);
    }, [idx]);

    return (
        <Section>
            <Section
                maxSize
                className={'h-full pt-[180px] md:pt-[140px] sm:pt-[103px] xs:pt-[103px] fold:pt-[103px] transition-all'}
            >
                        <ServiceSectionTypography
                            title={'결제내역 조회'}
                            className={'text-dark1A text-center xs:text-left fold:text-left mb-[40px] md:mb-[30px] sm:mb-[20px] xs:mb-[10px] fold:mb-[10px]'}
                        />

                        <div className={'flex flex-col justify-center items-center xs:items-start fold:items-start'}>
                                <div
                                    className={
                                        'text-bodyNormal xs:hidden fold:hidden text-center text-dark49 font-[500] leading-[40px] xs:text-bodySmall xs:leading-[30px] fold:text-bodySmall fold:leading-[30px] mb-[40px] md:mb-[30px] sm:mb-[20px] xs:mb-[10px] fold:mb-[10px] fade-in-up-sub'
                                    }>
                                        바움피앤에스는 판매자와 구매자의 결제를 중개하는 전자지급결제대행사(PG)입니다. <br/>
                                        카드 내역에 '바움피앤에스'가 표시되어 궁금하신 경우 아래에서 조회 가능합니다.
                                </div>

                                <div
                                    className={
                                        'smHidden:hidden text-bodyNormal xs:text-bodyXSmall text-dark49 font-[500] leading-[40px] xs:text-bodyXSmall xs:leading-[30px] fold:text-bodyXSmall fold:leading-[30px] mb-[40px] md:mb-[30px] sm:mb-[20px] xs:mb-[10px] fold:mb-[10px] fade-in-up-sub'
                                    }>
                                    바움피앤에스는 전자지급결제대행사(PG)입니다. <br/>
                                    카드 내역에 '바움피앤에스'가 표시되어 궁금하신 경우 <br/>
                                    아래에서 조회 가능합니다.
                                </div>

                                <div
                                    className={
                                        'text-bodyXSmall text-dark85 font-[300] leading-[30px] xs:hidden fold:hidden  xs:text-bodySmall xs:leading-[30px] fold:text-bodySmall fold:leading-[30px] mb-[10px] md:mb-[10px] sm:mb-[10px] xs:mb-[10px] fold:mb-[10px] fade-in-up-sub'
                                    }>
                                    * 바움피앤에스는 결제 서비스만 지원하는 회사로 결제를 취소하려면 상품을 구매하신 가맹점에 문의해주세요. <br/>
                                    (결제내역을 조회하면 가맹점 연락처를 확인 할 수 있습니다.) <br/>
                                    * 승인번호는 결제하신 카드사 문의 또는 카드사 앱에서 확인 가능합니다.
                                </div>
                        </div>

                        <TabNoNav items={xs ? mPAYVIEWabItems : PAYVIEWabItems} idx={idx} setIdx={setIdx}/>

                        <div className={'flex justify-center items-center xs:hidden fold:hidden'}>
                            <PayViewList
                                searchItems={idx === 0 ? payViewListItem : payViewCardListItem}
                                onChange={handleOnChange}
                                state={{ ...searchForm}}
                                modalTitle={ModalTitle}
                                modalContents={ModalContents}
                                idx={idx}
                                update={update}
                            />
                        </div>

                        <div className={'flex justify-start items-center smHidden:hidden'}>
                            <PayViewUnderline
                                searchItems={idx === 0 ? payViewListItem : payViewCardListItem}
                                onChange={handleOnChange}
                                state={{ ...searchForm}}
                                modalTitle={ModalTitle}
                                modalContents={ModalContents}
                                idx={idx}
                                update={update}
                            />

                        </div>

                        <div
                            className={
                                'text-[14px] text-dark85 font-[300] leading-[20px] mt-[20px] smHidden:hidden mb-[10px] md:mb-[10px] sm:mb-[10px] xs:mb-[10px] fold:mb-[10px] fade-in-up-sub'
                            }>
                            * 바움피앤에스는 결제 서비스만 지원하는 회사로 결제를   <br/> 취소하려면 상품을 구매하신 가맹점에 문의해주세요.
                            <br/>    (결제내역을 조회하면 가맹점 연락처를 확인 할 수 있습니다.)
                            <br/>  * 승인번호는 결제하신 카드사 문의 또는 카드사 앱에서 <br/> 확인 가능합니다.
                        </div>


                <div className={'h-auto mt-[40px] mb-[40px] border-t-dark1A border-b-darkDB border-b-[1px] border-t-2 border-solid'}>
                </div>

                <div className={'xs:hidden ss:hidden fold:hidden'}>
                    {data?.data &&
                        <Table
                            items={data?.data}
                            rows={paymentViewColumn}
                            // pageMeta={{
                            //     page: data?.data?.number + 1 || 0,
                            //     pageSize: data?.data?.size || 0,
                            //     total: data?.data?.totalElements || 0,
                            // }}
                            onClick={(rowData) => onClickReceipt2(rowData.bpTrinsId)}
                        />
                    }
                </div>

                {data?.data &&
                    <>
                        <ServiceSectionTypography
                            title={`${data?.data?.length || 0}건의 결제내역이 있습니다.`}
                            className={'text-dark1A xs:text-left fold:text-left mb-[20px] sm:hidden md:hidden mdl:hidden lg:hidden max:hidden'}
                        />

                        <div className="smHidden:hidden xs:text-left pb-[20px] mdl:hidden">
                            {data?.data.map((item, idx) => (
                                <div key={idx}
                                     className={`flex justify-between items-center ${idx !== data.data.length -1 ? 'border-b border-solid border-dark1A' : ''} pb-[10px] mb-[10px]`}>
                                    <div className="flex flex-col gap-2">
                                        <div className="text-[14px]">
                                            {getDayCalendarFormat(item.paymentDateTime)}
                                        </div>
                                        <div className="text-[18px] font-[500]">
                                            {item.companyName}
                                        </div>
                                    </div>
                                    <div className="pr-[14px] text-[14px] cursor-pointer" onClick={()=>onClickReceipt2(item.bpTrinsId)}>
                                        영수증 &gt;
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                }
            </Section>


        </Section>
    )
}

export default PaymentView;


