import styled from 'styled-components';

const TextStyle = styled.div`
  width: ${({width}) => width && width};
  height: ${({height}) => height && height};
  font-size: ${props => `${props.size}px` || '16px'};
  font-weight: ${props => props.weight || 400};
  color: ${props => props.color || '#000'};
  margin: ${props => props.margin || '0 0 0 0'};
  text-align: ${props => props.textAlign || 'left'};
  white-space: ${props => props.preWrap ? 'pre-wrap' : props.iswrap ? 'normal' : 'nowrap'};
  overflow: ${({overflow}) => (overflow ? overflow : '')};
  text-decoration-line: ${props => (props.isUnderLine ? 'underline' : props.isThrough ? 'line-through' : 'none')};
  text-underline-offset: 2px;
  text-overflow: ${({textOverflow}) => textOverflow && 'ellipsis'};
  line-height: ${({lineheight}) => lineheight && lineheight};
  z-index: ${({zIndex}) => (zIndex ? zIndex : 'none')};
  display: ${({required}) => required && 'flex'};
  background: ${props => props.background || 'none'};
  word-break: ${props => props.wordBreak || 'none'};
  ${props =>
    props.isCursor && {
        cursor: 'pointer',
    }};
  
`;

const RequiredDiv = styled.div`
  font-size: 12px;
  color: #ef4452; 
`;

const Text = ({preWrap,children, size, weight, color, onClick, margin, textAlign, iswrap, isUnderLine, isCursor, zIndex, textOverflow, width, overflow, height, required, lineheight, isThrough, background,wordBreak}) => {
    return (
        <TextStyle
            wordBreak={wordBreak}
            background={background}
            overflow={overflow}
            size={size}
            weight={weight}
            height={height}
            color={color}
            onClick={onClick}
            margin={margin}
            textAlign={textAlign}
            iswrap={iswrap}
            preWrap={preWrap}
            isUnderLine={isUnderLine}
            isCursor={isCursor}
            zIndex={zIndex}
            textOverflow={textOverflow}
            width={width}
            lineheight={lineheight}
            isThrough={isThrough}
            required={required}>
            {children}
            {required && (
                <RequiredDiv size={12} weight={400} color={'#EF4452'}>
                    *
                </RequiredDiv>
            )}
        </TextStyle>
    );
};

export default Text;
