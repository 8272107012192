import axios from 'axios';
const REFRESH_URL = '/reissue';

const http = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 5000,
});

// refresh 관련 로직
const getRefreshToken = async () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    const { status, data } = await http.post(REFRESH_URL, {
      accessToken,
      refreshToken,
    });
    if (status === 200) {
      const { accessToken, refreshToken } = data;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      return accessToken;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

// 요청시 인터셉터
http.interceptors.request.use(
  (config) => {
    // 로그인 요청인경우 제외
    if (
      !config.url.includes('/login') &&
      !window.location.pathname.includes('/customerService') &&
      !window.location.pathname.includes('/apply/service') &&
      !window.location.pathname.includes('/referenceRoom')
    ) {
      const accessToken = localStorage.getItem('accessToken');
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if ((config.method === 'post' || config.method === 'put') && config.url.includes('/file-board')) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }

    // 요청 보내기전 수행 로직
    return config;
  },
  (err) => {
    console.log('req err : ', err);
    return Promise.reject(err);
  }
);

// 응답시 인터셉터
http.interceptors.response.use(
  (res) => {
    const { data, status } = res;
    return { data, status };
  },

  async (err) => {
    console.log('err : ', err);
    const { config, response } = err;

    if (!response) {
      return {
        status: 408,
        data: '',
      };
    }
    const { status = 400, data } = response;
    // 리프레시 토큰 요청시 에러거나, refresh 요청 후 재요청의 무한반복인 경우 제외
    if (config.url === REFRESH_URL || status !== 401 || config.sent) {
      return { data, status: data.status };
    }

    // // 위 경우가 아닌경우, access토큰을 재발급 받음().
    config.sent = true;
    const accessToken = await getRefreshToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return http(config);
    }

    alert('토큰 만료로 다시 로그인 해주세요.');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    return (window.location.pathname = '/ad/login');
  }
);

export default http;
