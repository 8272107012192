import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import Section from './Section';
import styled from 'styled-components';
import { ReactComponent as MenuArrow } from '../../assets/icons/menuArrow.svg';
import { useMediaQuery } from '@mui/material';
import {ModalContext, ModalProvider} from "../../components/modal/modalContext";
import {fileBoardDataCreateAPI, fileBoardDataUpdateAPI} from "../../api/admin/fileBoard";
import {Toast} from "../../components/toast";

const footerSectionItems = [
	{
		content: '바움피앤에스 주식회사',
		className: '',
	},
	{
		content: '대표이사 : 김문수',
		className: 'footerMd:opacity-0 sm:opacity-0 xs:opacity-0 fold:opacity-0',
	},
	{
		content:
			'주소 : 인천시 연수구 인천타워대로 323 (송도 센트로드 Office A동 2907-9호)',
		className: 'footerMd:opacity-0',
	},
	{
		content: '사업자 등록번호 : 836-87-00147',
		className: 'fold:opacity-0a',
	},
	{
		content: '전자지급결제대행업 : 02-004-00144',
		className: 'footerMd:opacity-0 sm:opacity-0 xs:opacity-0 fold:opacity-0a',
	},
	{
		content: '외국환업무등록 : 02-004-00144',
		className: 'footerLg:opacity-0 fold:opacity-0a',
	},
	{
		content: '이메일 : info@baumpns.com',
		className: 'footerMd:opacity-0 sm:opacity-0 xs:opacity-0 fold:opacity-0',
	},

	{
		content: '운영시간 : 09:30 ~ 18:00',
		className: '',
	},
	{
		content: '팩스 : 032-232-5030',
		className: 'fold:opacity-0',
	},
	{
		content: '대표전화 : 1833-6199',
		className: '',
	},
];

const familySiteItems = [
	{
		title: '비유바움',
		link: 'https://bubaum.com',
	},
	{
		title: '바움컬처스',
		link: 'https://bucultures.com',
	},
	{
		title: '바움랩스',
		link: 'https://baumlabs.co.kr',
	},
	{
		title: '바움디스트리',
		link: 'https://baumdistri.com/',
	},
];

const Footer = () => {
	const media = useMediaQuery('(max-width: 800px)');
	const [selectedBoxSize, setSelectedBoxSize] = useState(0);
	const [showSelectedItem, setShowSelectedItem] = useState(false);
	const { openModal, closeModal } = useContext(ModalContext);

	const selectedBoxRef = useRef(null);
	const handleOnClickSelectedItem = (link) => {
		if(link == '') {
			return openModal('서비스 준비중 입니다.',{
				confirm: () => closeModal()
			},{type:'wait'},'보다 나은 서비스 제공을 위하여 준비중에 있습니다.');
		}
		setShowSelectedItem(false);
		window.open(link, '_blank', 'noopener, noreferrer');
	};

	useEffect(() => {
		if (!showSelectedItem) setSelectedBoxSize(0);
		if (showSelectedItem && selectedBoxRef?.current)
			setSelectedBoxSize(
				selectedBoxRef.current?.getBoundingClientRect().height,
			);
	}, [showSelectedItem, media]);

	return (
		<Section
			className={`bg-[rgba(0,0,0)] min-h-[240px] pt-[40px] text-[#858585] sm:pt-[20px] xs:pt-[20px] fold:pt-[20px] transition-all ${showSelectedItem ? 'h-auto pb-[10px]' : 'h-[40rem]'}`}>

		<Section maxSize>
				<div
					className={
						'flex flex-row sm:flex-col-reverse xs:flex-col-reverse fold:flex-col-reverse'
					}>
					<div
						className={
							'w-[90%] flex flex-col text-bodyXSmall leading-[35px] sm:text-bodyXXSmall sm:leading-[30px] xs:text-footerXS fold:text-footerXS  pb-[45px] sm:pb-[25px] xs:pb-[25px] fold:pb-[25px] '
						}>
						<div
							className={
								' max-w-[850px] min-w-[850px] footerMd:max-w-[560px] footerMd:min-w-[560px] sm:min-w-[450px] sm:max-w-[450px] xs:min-w-[360px] xs:max-w-[360px] fold:max-w-[250px] fold:min-w-[250px] xs:w-full fold:w-full flex flex-wrap h-auto xs:gap-[5px] fold:gap-[5px]'
							}>
							{footerSectionItems.map((item, index) => {
								if (index === 0) return;
								return (
									<FooterItems key={index}>
										<div className={'flex flex-row'}>
											<div className={`content${index} inline-block`} />
											<span
												className={`lineItem${index} mx-[5px] xs:mx-0 xs:ml-[5px] fold:mx-0 fold:ml-[5px]`}>
												|
											</span>
										</div>
									</FooterItems>
								);
							})}
						</div>
						<br />
						<div>ⓒ 2023. 바움피앤에스. All rights reserved.</div>
						<div className='flex'>
							<a
								className=' cursor-pointer'
								href='https://pullayo.com/terms/privacy'
								target='_blank'
								rel='noreferrer'>
								개인정보처리방침
							</a>
							<span
								className={`mx-[5px] xs:mx-0 xs:ml-[5px] fold:mx-0 fold:ml-[5px]`}>
												|
											</span>
							<a
								className=' cursor-pointer'
								href='https://pullayo.com/terms/trade'
								target='_blank'
								rel='noreferrer'>
								전자금융거래약관
							</a>
						</div>
					</div>
					<div
						className={
							' w-[200px] sm:w-full xs:w-full fold:w-full sm:mb-[20px] xs:mb-[20px] fold:mb-[20px]'
						}>
						<div
							className={'border-[1px] border-solid border-[#333] text-white'}
							tabIndex={0}
							onBlur={() => setShowSelectedItem(false)}>
							<DropDownBox
								ref={selectedBoxRef}
								onClick={() => {
									setShowSelectedItem(!showSelectedItem);
									if (!selectedBoxRef?.current) return;
									setSelectedBoxSize(
										selectedBoxRef.current.getBoundingClientRect().height,
									);
								}}
								className={
									'text-bodyXSmall xs:text-[13px] xs:leading-[15.5px] cursor-pointer'
								}>
								Family Site
								<MenuArrow
									className={`transition-all ${
										showSelectedItem ? 'rotate-[180deg]' : 'rotate-[0deg]'
									}`}
								/>
							</DropDownBox>
							<div
								className={`${
									showSelectedItem ? '' : 'h-0'
								} overflow-hidden transition-all`}
								style={{
									height:
										showSelectedItem && selectedBoxSize
											? selectedBoxSize * familySiteItems.length
											: '0',
								}}>
								{familySiteItems.map((item, index) => (
									<DropDownBox
										key={index}
										onClick={() => handleOnClickSelectedItem(item.link)}
										className={
											'text-bodyXSmall xs:text-[13px] xs:leading-[15.5px] itemHover'
										}>
										{item.title}
									</DropDownBox>
								))}
							</div>
						</div>
					</div>
				</div>
			</Section>
		</Section>
	);
};

export default Footer;

const FooterItems = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	& .content1:after {
		content: '바움피앤에스 주식회사';
	}
	& .content2:after {
		content: '대표이사 : 김문수';
	}
	& .content3:after {
		content: '주소 : 인천시 연수구 인천타워대로 323 (송도 센트로드 Office A동 2907-9호)';
	}
	& .content4:after {
		content: '사업자 등록번호 : 836-87-00147';
	}
	& .content5:after {
		content: '전자지급결제대행업 : 02-004-00144';
	}
	& .content6:after {
		content: '외국환업무등록 : 02-004-00144';
	}
	& .content7:after {
		content: '이메일 : info@baumpns.com';
	}
	& .content8:after {
		content: '운영시간 : 09:30 ~ 18:00';
	}
	& .content9:after {
		content: '팩스 : 032-232-5030';
	}
	& .content10:after {
		content: '대표전화 : 1833-6199';
	}
	&.lineItem0 {
		display: none;
	}
	@media (max-width: 1099.9px) {
		& span {
			opacity: 1;
			&.lineItem2,
			&.lineItem3,
			&.lineItem5,
			&.lineItem7,
			&.lineItem9 {
				opacity: 0;
			}
		}
	}
`;

const DropDownBox = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: auto;
	padding: 12px 10px 12px 20px;
	transition: all 0.3s;

	&.itemHover {
		cursor: pointer;
		&:hover {
			background: #dbdbdb;
			color: #494949;
		}
	}
	& svg {
		stroke: #fff;
		& path {
			stroke: #fff;
		}
	}
`;
