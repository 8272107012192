import dayjs from 'dayjs';

export const getCustomDayFormat = (time, format) => {
  return dayjs(time).format(format);
};

export const getDayWithWeek = (time) => {
  return dayjs(time).format('YYYY.MM.DD dddd');
};

export const getDayWithoutSeparator = (time) => {
  return dayjs(time).format('YYYYMMDD');
};

export const getDayFormatHypen = (time) => {
  return dayjs(new Date(time).toUTCString().replace(' GMT', '')).format('YYYY-MM-DD');
};
export const getDayFormatHypenV2 = (time) => {
  if (!time) return '';
  return dayjs(time).format('YYYY-MM-DD');
};

export const getDayCalendarFormat = (time) => {
  return dayjs(time).format('YYYY.MM.DD');
};

export const getFullDayFormatHypen = (time) => {
  if (!time) return '';
  return dayjs(time).format('YYYY-MM-DD hh:mm:ss');
};

export const getAnswerFormatHypen = (time) => {
  return dayjs(time).format('YYYY-MM-DD | hh:mm');
};

export const getDayFormatYearMonth = (time) => {
  return dayjs(time).format('YYYY.MM');
};
