
import FlexBox from "../receipt/Flexbox";
import selectBoxArrow from 'assets/images/select-box-arrow.png'
import {useState} from 'react';
import Select, {components} from 'react-select';

const SelectBoxArrow = ({width, height, onClick, isCursor}) => {
    return <img src={selectBoxArrow} alt="" width={width} onClick={onClick} height={height} style={{cursor: isCursor && 'pointer'}} />;
};

const selectboxStyle = ({width, isReadonly, isMulti, height, isOpen}) => ({
    container: (baseStyles, state) => ({
        ...baseStyles,
        height: 24,
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        width: width ? width : 344,
        height: 24,
        backgroundColor: isReadonly ? '#fff' : '#fff',
        border: 'none',
        borderBottom: isReadonly && '1px solid #A5ABB3',
        color: isReadonly ? '#a5abb3' : '#2C3A4B',
        paddingLeft: '6px',
        fontSize: '14px',
        borderRadius: 'none',
        minHeight: 24,
    }),
    IndicatorsContainer: (baseStyles, state) => ({
        ...baseStyles,
        height: 24,
    }),
    placeholder: defaultStyles => {
        return {
            ...defaultStyles,
            color: '#435469',
        };
    },
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            backgroundColor: isDisabled ? undefined : isSelected ? '#F6F9FF' : isFocused ? '#F6F9FF' : '#fff',
            color: isDisabled ? '#545D69' : isSelected ? '#0064FF' : '#545D69',
            cursor: isDisabled ? 'not-allowed' : 'default',
            zIndex: isFocused ? '9999' : 'auto',
            fontSize: '12px',
            minHeight: 24,
            height: 24,
            paddingTop: 0,
            paddingBottom: 0,
            display: 'flex',
            alignItems: 'center',
        };
    },
    dropdownIndicator: (base, state) => {
        return {
            ...base,
            border: 'none',
            transform: isOpen ? 'scaleY(-1)' : 'scaleY(1)',
            height: 24,
            paddingLeft: '0px',
            paddingRight: '0px',
        };
    },
    menu: (base, state) => {
        return {
            ...base,
            border: 'none',
            zIndex: '9999',
            fontSize: '11px',
            height: 24,
            minHeight: 24,
            boxShadow: 'none',
        };
    },
    menuList: (base, state) => {
        return {
            ...base,
            backgroundColor: '#fff',
            boxShadow: '1px 1px 2px grey;',
            borderRadius: 'none',
        };
    },

    singleValue: (base, state) => {
        return {
            ...base,
            color: '#2C3A4B',
            fontSize: '11px',
        };
    },
    multiValue: (base, state) => {
        return {
            ...base,
            color: '#2C3A4B',
            backgroundColor: 'none',
            fontSize: '18px',
        };
    },
    valueContainer: (base, state) => {
        return {
            ...base,
            display: 'flex',
            flexWrap: 'nowrap',

            padding: '0px',
        };
    },
    listBox: (base, state) => {
        return {
            ...base,
        };
    },
});

const ReceiptSelectBox = ({
                              option = [{label: '데이터 없음', value: 1}],
                              name,
                              onChange,
                              width,
                              height,
                              value,
                              placeholder = '',
                              isReadonly,
                              isMulti,
                              isSearchable = false,
                              isNotIndicatorsContainer = false,
                              refs,
                          }) => {
    const [isOpen, setIsOpen] = useState(false);
    const DropdownIndicator = props => {
        return (
            <>
                {!isNotIndicatorsContainer && (
                    <components.DropdownIndicator {...props}>
                        <FlexBox margin={'0 0px 0 0 '}>{!isReadonly && <SelectBoxArrow />}</FlexBox>
                    </components.DropdownIndicator>
                )}
            </>
        );
    };
    return (
        <Select
            onMenuOpen={() => {
                setIsOpen(true);
            }}
            onMenuClose={() => {
                setIsOpen(false);
            }}
            options={option}
            value={option.find(obj => obj.value === value)}
            styles={selectboxStyle({width: width, isReadonly: isReadonly, isMulti: isMulti, height: height, isOpen: isOpen})}
            isSearchable={isSearchable}
            components={{DropdownIndicator, IndicatorSeparator: () => null}}
            onChange={e => onChange(e, name)}
            placeholder={placeholder}
            isDisabled={isReadonly}
            isMulti={isMulti}
            ref={refs}
        />
    );
};

export default ReceiptSelectBox;
