export const applyServiceColumn = [
  { key: 'no', label: 'No', width: '100px' },
  { key: 'createdDate', label: '등록일시', width: '200px' },
  { key: 'checkerName', label: '상태', width: '90px' },
  { key: 'companyName', label: '회사명', width: '180px' },
  { key: 'manager', label: '담당자명', width: '135px' },
  { key: 'phone', label: '연락처', width: '180px' },
  { key: 'email', label: '이메일' },
  { key: 'companyRegNum', label: '사업자번호', width: '180px' },
  { key: 'btn', label: '상세보기', width: '120px' },
];
export const referenceRoomColumn = [
  { key: 'no', label: 'No', width: '100px' },
  { key: 'createdDate', label: '등록일시', width: '200px' },
  // { key: 'type', label: '카테고리', width: '200px' },
  { key: 'title', label: '제목', cellAlign: 'left' },
  { key: 'btn', label: '수정', width: '120px' },
];
export const faqColumn = [
  { key: 'no', label: 'No', width: '100px' },
  { key: 'createdDate', label: '등록일시', width: '200px' },
  { key: 'category', label: '카테고리', width: '200px' },
  { key: 'title', label: '제목', cellAlign: 'left' },
  { key: 'btn', label: '수정', width: '120px' },
];
export const noticeColumn = [
  { key: 'no', label: 'No', width: '100px' },
  { key: 'createdDate', label: '등록일시', width: '200px' },
  { key: 'fix', label: '중요여부', width: '200px' },
  { key: 'title', label: '제목', cellAlign: 'left' },
  { key: 'btn', label: '수정', width: '120px' },
];

export const questionColumn = [
  { key: 'no', label: 'No', width: '100px' },
  { key: 'createdDate', label: '등록일시', width: '200px' },
  { key: 'useAnswer', label: '상태', width: '90px' },
  { key: 'inquiryType', label: '문의유형', width: '160px' },
  { key: 'name', label: '성명', width: '135px' },
  { key: 'email', label: '이메일', width: '200px' },
  { key: 'title', label: '제목', cellAlign: 'left' },
  { key: 'btn', label: '상세보기', width: '120px' },
];
export const managerManagement = [
  { key: 'no', label: 'No', width: '100px' },
  { key: 'createdDate', label: '등록일시', width: '200px' },
  { key: 'username', label: '아이디', width: '180px' },
  { key: 'name', label: '이름', width: '180px' },
  { key: 'phone', label: '휴대폰번호', width: '180px' },
  { key: 'email', label: '이메일' },
  { key: 'affiliation', label: '소속', width: '180px' },
  { key: 'btn', label: '수정', width: '120px' },
];

// 결제내역 조회 컬럼
export const paymentViewColumn = [
  { key: 'paymentDateTime', label: '결제일시', width: '100px' },
  { key: 'paymentStatus', label: '결제상태', width: '200px' },
  { key: 'authNumber', label: '승인번호', width: '90px' },
  { key: 'orderNumber', label: '주문번호', width: '160px' },
  { key: 'companyName', label: '가맹점명', width: '135px' },
  { key: 'phoneNumber', label: '가맹점 전화번호', width: '200px' },
  { key: 'amount', label: '결제금액',},
  { key: 'cancelAmount', label: '취소금액', width: '120px' },
  { key: 'cardQuota', label: '할부구분',},
  { key: 'btn', label: '영수증', width: '120px', cellAlign:'right'},
];
