import React, { memo } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { getFullDayFormatHypen } from '../../utility/admin/timeFormat';
import Button from './Button';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {appendCommas, bizNoFormatter, formatPhoneNumber} from 'utility/format';
import { ReactComponent as Receipt } from 'assets/icons/admin/receipt.svg';

const ListTable = ({ items, rows, pageMeta, onClick }) => {
  const tableEditor = (key, item, state, index) => {
    if (key === 'no') {
      const { page, pageSize, total } = pageMeta;
      const rnum = total + (1 - page) * pageSize - index;
      return rnum;
    }
    if (key === 'checkerName') return item ? '확인' : '미확인';
    if (key === 'createdDate' || key === 'updatedDate') return getFullDayFormatHypen(item);
    if (key === 'type') {
      if (item === 1) return 'PG서비스';
      if (item === 2) return '통신개통';
      if (item === 3) return 'BigOne ERP';
    }
    if (key === 'paymentDateTime') return getFullDayFormatHypen(item);
    if (key === 'amount' || key === 'cancelAmount') return appendCommas(item);
    if (key === 'useAnswer') return item ? '답변완료' : '답변대기';
    if (key === 'category') {
      if (item === 1) return '결제관리/정산관리';
      if (item === 2) return '결제문의/정산문의';
      if (item === 3) return '이용문의';
    }
    if (key === 'phone') return formatPhoneNumber(item);
    if (key === 'companyRegNum') return bizNoFormatter(item);
    if (key === 'fix') {
      if (item === 0) return '일반';
      if (item === 1) return '중요';
    }
    if (key === 'btn') {
      const label = rows.find((row) => row.key === 'btn')?.label;
      return (
        <span className={'flex items-center justify-center'}>
          <Button style={{ fontSize: '12px', padding: '6px 12px' }} type={'outlined'} onClick={() => onClick(state)}>
            {label}
          </Button>
        </span>
      );
    }
    return item;
  };

  return (
    <StyledTableContainer component={Paper} className={'mb-[60px]'} elevation={0}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {rows?.map((row) => (
              <StyledTableHeadCell key={row.key} align="center" width={row.width ? row.width : ''}>
                {row.label}
              </StyledTableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.length > 0 ? (
            items.map((item, index) => (
              <StyledTableRow key={item.id}>
                {rows.map((row) => {
                  return (
                    <StyledTableCell key={row.key} align={row.cellAlign || 'center'}>
                      {tableEditor(row.key, item[row.key], item, index)}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow height={200}>
              <StyledTableCell colSpan={100} style={{ textAlign: 'center' }}>
                데이터가 없습니다.
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default memo(ListTable);

const StyledTableContainer = styled(TableContainer)`
  border: 1px solid var(--color-admin-border);
  border-radius: var(--border-radius);
`;

const StyledTableHeadCell = styled(TableCell)`
  background: var(--color-admin-navy);
  border-bottom: 1px solid var(--color-admin-border);
  color: #fff;
  font-family: 'Pretendard';
`;

const StyledTableCell = styled(TableCell)`
  font-family: 'Pretendard';
  color: var(--color-dark-1);
  border-bottom: 1px solid var(--color-admin-border);
`;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
