import { css, styled } from 'styled-components';

/*Heading */
const Heading1 = css`
  font-size: 44px;
  font-weight: 700;
  line-height: 1.3;
`;
const Heading2 = css`
  font-size: 36px;
  font-weight: 700;
  line-height: 1.3;
`;
const Heading3 = css`
  font-size: 28px;
  font-weight: 700;
  line-height: 1.3;
`;
const Heading4 = css`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
`;
const Heading5 = css`
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
`;
const Heading6 = css`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
`;
const LeadParagraph = css`
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5;
`;
const BodyLarge = css`
  font-size: 20px;
  font-weight: 400;
  line-height: 1.7;
`;
const BodyMedium = css`
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7;
`;
const BodyNormal = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
`;
const BodySmall = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
`;
const BodyXSmall = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7;
`;

// 어드민 페이지 공통 style
const ContentContainer = styled.div`
  user-select: text;
  max-width: 1444px;
  width: 100%;
  margin: auto;
`;
const PageTitle = styled.h4`
  ${Heading4}
  margin-bottom: 24px;
  color: var(--color-dark-1);
`;

/** FormBox 시작*/
const FormBox = styled.div`
  border-top: 2px solid var(--color-admin-border);
  display: flex;
  flex-direction: column;
`;
const FormBoxItemTitle = styled.div`
  height: 100%;
  width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-light-4);
  ${BodySmall}
  color: var(--color-dark-1);
`;
const FormBoxItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-admin-border);
  min-height: ${({ height }) => (height ? height : '66px')};
  height: ${({ height }) => (height ? height : 'auto')};
  > ${FormBoxItemTitle} {
    line-height: ${({ height }) => (height ? height : '66px')};
  }
`;

const FormBoxItemValue = styled.div`
  ${BodySmall}
  height: 100%;
  width: calc(100% - 128px);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--color-dark-1);
  padding: 12px 18px;
  //background-color: white;
  background-color: #fff;

  > fieldset {
    width: 148px;
  }
`;

const UploadEmptyBox = styled.div`
  height: 150px;
  width: 200px;
  border: 1px solid var(--color-admin-border);
  border-radius: var(--border-radius);
`;
const UploadBox = styled.div`
  height: ${({ height }) => (height ? height : 'auto')};
  width: ${({ width }) => (width ? width : 'auto')};
  border: 1px solid var(--color-admin-border);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const UploadBoxWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const UploadBoxs = styled.div`
  display: flex;
  flex-direction: column;
  > ${UploadBoxWrapper} {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const UploadBtns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 12px;
  padding-bottom: 8px;
`;
const UploadBtn = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-size: 12px;
  font-weight: 500;
  width: 100px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dde5e9;
  border-radius: var(--border-radius);
`;
const UploadCaution = styled.div`
  font-size: 12px;
  color: var(--color-dark-3);
  position: relative;
  padding-left: 6px;
  margin-bottom: 2px;
  &:last-child {
    margin: 0;
  }
  &::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: var(--color-dark-3);
    left: 0;
    top: calc(50% - 1.5px);
  }
`;
const BottomBtnWrapper = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
`;

/** SearchBox style*/

const SearchRow = styled.div`
  height: 60px;
  display: flex;
  color: var(--color-dark-1);
  border-bottom: 1px solid var(--color-admin-border);
  &:last-child {
    border-bottom: none;
  }
`;
const SearchKey = styled.div`
  width: 132px;
  height: 100%;
  background-color: #fafafc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--color-dark-1);
`;
const SearchValue = styled.div`
  width: calc(100% - 132px);
  display: flex;
  align-items: center;
  padding-left: 18px;
`;
const SearchItem = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  &:first-child > ${SearchKey} {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
`;
const SearchBox = styled.div`
  border: 1px solid var(--color-admin-border);
  border-radius: var(--border-radius);
  margin-bottom: 12px;
  &.reference_search {
    > div {
      > ${SearchItem}:nth-child(-n + 2) {
        border-bottom: none;
      }
    }
  }
  div {
    > ${SearchItem}:nth-child(-n + 2) {
      border-bottom: 1px solid var(--color-admin-border);
    }
  }
`;

const SearchBoxPayView = styled.div`
  border: 1px solid var(--color-admin-border);
  border-radius: var(--border-radius);
  margin-bottom: 12px;
  padding-right: 12px;
  &.reference_search {
    > div {
      > ${SearchItem}:nth-child(-n + 2) {
        border-bottom: none;
      }
    }
  }
  div {
    > ${SearchItem}:nth-child(-n + 2) {
      border-bottom: 1px solid var(--color-admin-border);
    }
  }
`;

export const TableWrapStyled = styled.div`
  .d-flex {
    display: flex;
  }
  .mr-10 {
    margin-right: 10px;
    @media all and (max-width: 510px) {
      margin-right: 6px;
    }
  }
  .mb-14 {
    margin-bottom: 14px;
    @media all and (max-width: 510px) {
      margin-bottom: 8px;
    }
  }
  .al-center {
    align-items: center;
  }

  table {
    border-top: 2px solid var(--color-admin-border);
    border-collapse: collapse;
    width: 100%;
    tbody {
      tr {
        border-bottom: 1px solid var(--color-admin-border);
        th {
          width: 128px;
          align-items: center;
          background-color: var(--color-light-4);
          font-size: 14px;
          font-weight: 400;
          line-height: 1.7;
          color: var(--color-dark-1);
        }
        td {
          font-weight: 400;
          line-height: 1.7;
          width: calc(100% - 128px);
          font-size: 14px;
          color: var(--color-dark-1);
          & > .content {
            word-break: break-all;
            word-wrap: break-word;
            white-space: pre-line;
            max-width: 1000px;
          }
        }
        th,
        td {
          padding: 10px;
          vertical-align: middle;
          color: var(--color-dark-1);
          height: 60px;
        }
      }
      //mobile
      @media all and (max-width: 510px) {
        tr {
          th {
            width: 30%;
          }
          th,
          td {
            height: 49px;
            span {
              &.mobile-text {
                display: block;
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
`;

export {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  LeadParagraph,
  BodyLarge,
  BodyMedium,
  BodyNormal,
  BodySmall,
  BodyXSmall,
  ContentContainer,
  PageTitle,
  FormBox,
  FormBoxItem,
  FormBoxItemTitle,
  FormBoxItemValue,
  UploadBoxWrapper,
  UploadEmptyBox,
  UploadBoxs,
  UploadBox,
  UploadBtns,
  UploadBtn,
  UploadCaution,
  SearchBox,
  SearchBoxPayView,
  SearchRow,
  SearchKey,
  SearchValue,
  SearchItem,
  BottomBtnWrapper,
};
