import { Navigate, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

// web
import Main from './pages/web/main/Main';
import NotFound from './pages/web/404/NotFound';
import Layout from './layout/web/Layout';
import Payment from './pages/web/services/Payment';
import Platform from './pages/web/services/Platform';
import Additional from './pages/web/services/Additional';
import ApplyProcedure from './pages/web/applys/ApplyProcedure';
import ServiceApply from './pages/web/applys/ServiceApply';
import ReferenceRoom from './pages/web/referenceRoom/ReferenceRoom';
import FrequentlyAskedQuestion from './pages/web/customerService/FrequentlyAskedQuestion';
import ServiceNotice from './pages/web/customerService/notice/ServiceNotice';
import ServiceQuestion from './pages/web/customerService/question/ServiceQuestion';
import NoticeDetail from './pages/web/customerService/notice/NoticeDetail';
import CreatedQuestion from './pages/web/customerService/question/CreatedQuestion';
import QuestionDetail from './pages/web/customerService/question/QuestionDetail';
import CEOGreeting from './pages/web/company/CEOGreeting';
import Directions from './pages/web/company/Directions';
import PrivacyPolicy from './pages/web/privacy/PrivacyPolicy';

// admin
import AdminLayout from './layout/admin';

import AdminLogin from './pages/admin/Login';
import AdminAuthInfoForm from './pages/admin/auth/InfoForm';
import AdminPwChangeForm from './pages/admin/auth/PwChangeForm';
import AdminApplyList from './pages/admin/apply/List';
import AdminApplyDetail from './pages/admin/apply/Detail';
import AdminReferenceList from './pages/admin/reference/List';
import AdminReferenceForm from './pages/admin/reference/Form';
import AdminFaqList from './pages/admin/faq/List';
import AdminFaqForm from './pages/admin/faq/Form';
import AdminNoticeList from './pages/admin/notice/List';
import AdminNoticeForm from './pages/admin/notice/Form';
import AdminQuestionList from './pages/admin/question/List';
import AdminQuestionDetail from './pages/admin/question/Detail';
import AdminUserForm from './pages/admin/user/Form';
import AdminUserEditForm from './pages/admin/user/EditPw';
import AdminUserList from './pages/admin/user/List';
import AdminAuthMiddleWhere from 'hoc/AdminAuthMiddleWhere';

import { StyledToastConatiner } from 'components/toast';
import { ModalProvider } from 'components/modal/modalContext';
import NoticePopUp from "./pages/web/main/NoticePopUp";
import PaymentView from "./pages/web/paymentView/paymentView";
import Receipt from "./components/receipt/Receipt";

const App = () => {
	return (
		<ModalProvider>
			<StyledToastConatiner newestOnTop />
			<Routes>
				<Route path={'/noticeAlert'} element={<NoticePopUp />} />
				<Route path={'/'} element={<Layout />}>
					<Route index element={<Main />} />
					<Route path={'/company'}>
						<Route path={'greeting'} element={<CEOGreeting />} />
						<Route path={'directions'} element={<Directions />} />
					</Route>
					<Route path={'/service'}>
						<Route path={'payment'} element={<Payment />} />
						<Route path={'platform'} element={<Platform />} />
						<Route path={'additional'} element={<Additional />} />
					</Route>
					<Route path={'/apply'}>
						<Route path={'procedure'} element={<ApplyProcedure />} />
						<Route path={'service'} element={<ServiceApply />} />
					</Route>
					<Route path={'/referenceRoom'} element={<ReferenceRoom />}>
						{/*<Route path={':category'} element={<ReferenceRoom />} />*/}
					</Route>
					<Route path={'/customerService'}>
						<Route
							path={'frequentlyAskedQuestion'}
							element={<FrequentlyAskedQuestion />}>
							<Route path={':category'} element={<FrequentlyAskedQuestion />} />
						</Route>
						<Route path={'notice'}>
							<Route index element={<ServiceNotice />} />
							<Route path={':id'} element={<NoticeDetail />} />
							<Route
								path={'*'}
								element={<Navigate to='/customerService/notice' />}
							/>
						</Route>
						<Route path={'question'}>
							<Route index element={<ServiceQuestion />} />
							<Route path={'create'} element={<CreatedQuestion />} />
							<Route path={'detail'} element={<QuestionDetail />} />
						</Route>
						<Route path={'*'} element={<NotFound />} />
					</Route>
					<Route path={'/paymentView'} element={<PaymentView />}></Route>
					<Route path={'*'} element={<NotFound />} />
				</Route>
				<Route path={'/chit'} element={<Receipt />} />
				<Route path={'/privacy-policy'} element={<PrivacyPolicy />}></Route>
				{/*Admin*/}
				<Route path={'/ad'} element={<AdminAuthMiddleWhere />}>
					<Route path={'login'} element={<AdminLogin />} />
					<Route element={<AdminLayout />}>
						<Route index element={<Navigate to='/ad/apply' />} />
						{/* 관리자 > 계정 */}
						<Route path={'auth'} element={<AdminAuthInfoForm />} />
						<Route path={'pwchange'} element={<AdminPwChangeForm />} />
						{/* 관리자 > 서비스 신청 */}
						<Route path={'apply'} element={<AdminApplyList />} />
						<Route path={'apply_detail/:id'} element={<AdminApplyDetail />} />
						{/* 관리자 > 자료실 */}
						<Route path={'reference'} element={<AdminReferenceList />} />
						<Route path={'reference_reg'} element={<AdminReferenceForm />} />
						<Route
							path={'reference_edit/:id'}
							element={<AdminReferenceForm />}
						/>
						{/* 관리자 > FAQ */}
						<Route path={'faq'} element={<AdminFaqList />} />
						<Route path={'faq_reg'} element={<AdminFaqForm />} />
						<Route path={'faq_edit/:id'} element={<AdminFaqForm />} />
						{/* 관리자 > 공지사항 */}
						<Route path={'notice'} element={<AdminNoticeList />} />
						<Route path={'notice_reg'} element={<AdminNoticeForm />} />
						<Route path={'notice_edit/:id'} element={<AdminNoticeForm />} />
						{/* 관리자 > 문의 */}
						<Route path={'question'} element={<AdminQuestionList />} />
						<Route
							path={'question_detail/:id'}
							element={<AdminQuestionDetail />}
						/>
						{/* 관리자 > 관리자 관리 */}
						<Route path={'user'} element={<AdminUserList />} />
						<Route path={'user_reg'} element={<AdminUserForm />} />
						<Route path={'user_edit/:id'} element={<AdminUserForm />} />
						<Route path={'user_edit/pw/:id'} element={<AdminUserEditForm />} />
						<Route path={'404'} element={<NotFound />} />
						<Route path={'*'} element={<NotFound />} />
					</Route>
				</Route>
			</Routes>
		</ModalProvider>
	);
};

export default App;
